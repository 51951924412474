// Portal.js
import { useEffect } from 'react';
import { usePortal } from '../provider/portal-provider';

let nextKey = 0;

export const Portal = ({ children }) => {
  const { registerPortal, unregisterPortal } = usePortal();
  const key = nextKey++;

  useEffect(() => {
    registerPortal(key, children);

    return () => {
      unregisterPortal(key);
    };
  }, [children, key, registerPortal, unregisterPortal]);

  return null;
};
