import { View, Image, useSx, Text, useDripsyTheme } from 'dripsy';
import React, { useMemo, useState } from 'react';
import { MotiView } from 'moti';
import { Modal } from 'react-native';
import { Icon } from '../icon/icon';
import { MotiPressable } from 'moti/interactions';
import { A } from '../../components/typography/a';
import { useAlert } from 'app/redux/hooks/alert';
import { Accordion } from '../accordion';
import { MotiLink } from 'solito/moti';
import { Button } from '../button/common-button';
import { Link } from '../link';
import { hideMoreText } from '../../util/helper';
import { useSelector } from 'react-redux';
import { I18Text } from 'app/provider/i18-provider';
import { getClientConfig } from '../../redux/memoizedSelectors';
interface ProfileDropDownProps {
  open: boolean;
  user: any;
  onClose: (e: boolean) => void;
  logout?: (navigate: any) => void;
  isConfirmed?: boolean;
}

function ProfileDropDown({
  open,
  user,
  onClose,
  logout,
}: ProfileDropDownProps) {
  const sx = useSx();
  const Alert = useAlert();
  const [loading, setLoading] = useState<boolean>(false);
  const [toggle, setToggle] = useState(false);
  const profileName = `${user && user.firstName} ${(user && user.lastName) || ''}`;
  const { clientConfig } = useSelector(getClientConfig);
  const { menulistTabs ,footerlistTabs } = clientConfig || {};

  const shouldDisplayFooter = (key: any) => {
    const items = footerlistTabs?.find((item: any) => item?.key === key);
    return footerlistTabs?.length > 0 ? items ? items?.active ? 'flex' : 'none' : 'flex' : 'flex';
  };

  return (
    <MotiPressable
      animate={useMemo(
        () =>
          ({ hovered, pressed }) => {
            'worklet';
            return {
              opacity: hovered || pressed ? 0.5 : 1,
            };
          },
        []
      )}
      transition={useMemo(
        () =>
          ({ hovered, pressed }) => {
            'worklet';
            return {
              delay: hovered || pressed ? 0 : 100,
            };
          },
        []
      )}
    >
      <MotiView
        transition={{ type: 'timing', delay: 20, value:{} }}
        onClick={() => onClose(false)}
      >
        <Modal
          animationType="none"
          transparent={true}
          visible={loading ? loading : open}
          onRequestClose={() => onClose(false)}
        >
          <View
            style={[
              sx({
                alignItems: 'flex-end',
                top: 50,
              }),
            ]}
          >
            <View
              style={[
                sx({
                  margin: 2,
                  marginRight: 3,
                  backgroundColor: 'white',
                  borderRadius: 10,
                  paddingTop: 2,
                  paddingBottom: 3,
                  width: 280,
                  shadowColor: 'grey',
                  shadowOffset: {
                    width: 0,
                    height: 0,
                  },
                  shadowOpacity: 0.6,
                  shadowRadius: 10,
                  elevation: 10,
                }),
              ]}
            >
              <View
                style={[
                  sx({
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderBottomWidth: 1,
                    borderBottomColor: '$brightGrey',
                    paddingY: 2,
                    paddingX: 3,
                  }),
                ]}
              >
                <Image
                  source={{ uri: user?.avatar }}
                  defaultSource={require('../../assets/Enote.svg')}
                  resizeMode={'cover'}
                  resizeMethod={'scale'}
                  style={[
                    sx({
                      height: [33],
                      minWidth: [33],
                      borderWidth: 1,
                      borderRadius: 33,
                      marginRight: 3,
                    }),
                  ]}
                />
                <View>
                  <Text
                    style={[
                      sx({
                        fontWeight: 'bold',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        flex: 1,
                        fontSize: 15,
                        color: '$fontGrey',
                      }),
                    ]}
                  >
                    <Link
                      isNextLink={false}
                      href={`/u/${user && user.username}`}
                    >
                      <Text>{hideMoreText(profileName, 19)}</Text>
                    </Link>
                  </Text>
                </View>
              </View>
              {clientConfig?.classroomTabs?.isClassroomActive ||
              user?.role === 65535 ? (
                <MotiLink href={'/classroom'}>
                  <MenuItem
                    data={{
                      title: menulistTabs?.Classroom?.displayName || 'Smart Classroom',
                      icon: 'classroom',
                    }}
                  />
                </MotiLink>
              ) : (
                <></>
              )}
              {clientConfig?.referAndEarn || user?.role === 65535 ? (
                <MotiLink href={'/refer-earn'}>
                  <MenuItem
                    data={{
                      title: menulistTabs?.ReferEarn?.displayName || 'Refer and Earn',
                      icon: 'refer',
                    }}
                  />
                </MotiLink>
              ) : (
                <></>
              )}
              {/* {clientConfig?.menu?.includes('Campus Ambassador') || user?.role === 65535 ? (
                <MotiLink
                  href={
                    user?.role === 3
                      ? `/wallet/lca/${user.username || userName}`
                      : '/ca'
                  }
                >
                  <MenuItem
                    data={{
                      title: 'Campus Ambassador',
                      icon: 'ambassador',
                    }}
                  />
                </MotiLink>
              ) : (
                <></>
              )} */}

              {clientConfig?.menu?.includes('Settings') || user?.role === 65535 ? (
                <MotiPressable
                  animate={({ hovered, pressed }) => {
                    'worklet';
                    return {
                      backgroundColor:
                        hovered || pressed ? '$cultured' : '$primary',
                    };
                  }}
                >
                  <Accordion
                    icon={undefined}
                    iconFill={['#000000']}
                    title={
                      <A href={`/settings/${user && user.username}/security`}>
                        <MenuItem
                          data={{
                            title: menulistTabs?.Settings?.displayName || 'Settings',
                            icon: 'setting',
                            toggle: toggle,
                            accordion: true,
                          }}
                        />
                      </A>
                    }
                    titleStyles={sx({ fontSize: 15, color: '$fontGrey' })}
                    onToggle={(data) => {
                      setToggle(data);
                    }}
                    contents={
                      <View
                        style={sx({
                          paddingLeft: 4,
                          marginLeft: 3,
                        })}
                      >
                        <MotiLink
                          href={`/settings/${user && user.username}/security`}
                          style={sx({ paddingBottom: 10 })}
                          animate={({ hovered, pressed }) => {
                            'worklet';
                            return {
                              opacity: pressed ? 0.9 : hovered ? 0.5 : 1,
                            };
                          }}
                        >
                          <MenuItem
                            data={{
                              title:<I18Text>Security</I18Text>,
                              subMenu: true,
                            }}
                          />
                        </MotiLink>

                        <MotiLink
                          href={`/settings/${
                            user && user.username
                          }/device-management`}
                          animate={({ hovered, pressed }) => {
                            'worklet';
                            return {
                              opacity: pressed ? 0.9 : hovered ? 0.5 : 1,
                            };
                          }}
                        >
                          <MenuItem
                            data={{
                              title:<I18Text>Device Management</I18Text>,
                              subMenu: true,
                            }}
                          />
                        </MotiLink>
                      </View>
                    }
                    containerStyles={sx({
                      padding: 0,
                      backgroundColor: toggle ? '$cultured' : '$white',
                    })}
                    contentStyles={sx({
                      paddingTop: 0,
                    })}
                  />
                </MotiPressable>
              ) : (
                <></>
              )}
              {clientConfig?.menu?.includes('Help & Support') || user?.role === 65535 ? (
                <MotiLink href={'/contact-us'}>
                  <MenuItem
                    data={{
                      title: menulistTabs?.HelpSupport?.displayName || 'Help & Support',
                      icon: 'support',
                    }}
                  />
                </MotiLink>
              ) : (
                <></>
              )}
              {(typeof clientConfig?.logout === 'undefined' || clientConfig?.logout) ? <MenuItem
                data={{
                  title: 'Log Out',
                  icon: 'logout',
                }}
                loading={loading}
                onPress={() => {
                  Alert({
                    buttons: [
                      {
                        name: 'Cancel',
                      },
                      {
                        name: 'Confirm',
                        onPress: () => {
                          setLoading(true);
                          logout &&
                            logout(() => {
                              setLoading(false);
                            });
                        },
                      },
                    ],
                    message:
                      ' Are you sure you want to log out of this device ? We will miss you. But you can always log back in at any time.',
                    title: `Log out of ${clientConfig?.name}?`,
                    type: 'warn',
                  });
                }}
              />:<></>}

              <View
                style={{
                  alignItems: 'center',
                }}
              >
                <Text
                  style={[
                    sx({
                      marginBottom: 1,
                      marginTop: 2,
                      fontSize: 12,
                      color: '$darkSilver',
                      lineHeight: 20,
                      letterSpacing: 1,
                    }),
                  ]}
                >
                  <A href="/privacy"><I18Text>Privacy</I18Text></A> | <A href="/tou"><I18Text>Terms</I18Text></A> |{' '}
                  <A style={{ display : shouldDisplayFooter('aboutUs') }} href="/aboutus"><I18Text>About</I18Text> |</A> <A href="/careers"><I18Text>Careers</I18Text></A>
                </Text>
                <Text
                  style={[
                    sx({
                      textAlign: 'center',
                      fontSize: 12,
                      color: '$darkSilver',
                      lineHeight: 20,
                      letterSpacing: 1,
                    }),
                  ]}
                >
                 {`${clientConfig?.name} Pvt Ltd. © ${new Date().getFullYear()}`}
                </Text>
              </View>
            </View>
          </View>
        </Modal>
      </MotiView>
    </MotiPressable>
  );
}

export default ProfileDropDown;

export const MenuItem = ({
  data,
  onPress,
}: {
  loading?: boolean;
  data: any;
  onPress?: () => void;
}) => {
  const sx = useSx();
  const [hover, setHover] = useState(false);
  const { theme }: any = useDripsyTheme();

  const handlePressed = () => {
    onPress && onPress();
  };

  return (
    <Button
      variantType="menu"
      onPress={() => {
        handlePressed();
      }}
      onHoverIn={() => {
        setHover(true);
      }}
      onHoverOut={() => {
        setHover(false);
      }}
      animate={({ hovered }) => {
        'worklet';
        return {
          opacity: hovered ? 1 : 1,
        };
      }}
      style={sx({
        backgroundColor:
          (hover && !data.accordion) || data.subMenu || data.toggle
            ? '$cultured'
            : '$white',
        flexDirection: 'row',
        alignItems: 'center',
        width: '98%',
        paddingX: 3,
        paddingTop: data.subMenu ? 0 : 2,
        paddingBottom: data.subMenu ? 1 : 2,
      })}
      contentStyle={sx({
        justifyContent: ['flex-start'],
      })}
      startIcon={
        !!data.icon && (
          <View
            style={sx({
              backgroundColor:
                (hover && !data.accordion) || data.toggle
                  ? '$primary'
                  : '$cultured',
              height: 33,
              width: 33,
              borderRadius: 33,
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: 3,
            })}
          >
            <Icon
              name={data.icon}
              fill={
                (hover && !data.accordion) || data.toggle
                  ? [theme?.colors['$white']]
                  : [theme?.colors['$primary']]
              }
            />
          </View>
        )
      }
    >
      <I18Text  
      style={sx({
        marginY: 0,
        marginBottom: 0,
        flexWrap: 'wrap',
        color: '$fontGrey',
        fontSize: data.subMenu ? 13 : 16,
      })}>
                {data.title}
      </I18Text>
    </Button>
  );
};
