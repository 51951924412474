import { Image, useSx, View } from 'dripsy';
import React, { ReactNode } from 'react';
// import { H4 } from '../typography/h4';

interface EmptyProps {
  noDataFoundText?: string
  icon?: ReactNode
}

export const Empty = ({
  // noDataFoundText='No data found',
  icon = <Image source={require('../../assets/no-data-found.webp')} style={{ width: '100%', height: '100%' }} />,
}: EmptyProps) => {
  const sx = useSx();
  return (
    <View style={sx({
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: [200]
    })}>
      <View
        style={sx({
          height: [100],
          width: [160]
        })}
      >
        {icon}
        {/* {typeof noDataFoundText === 'string' ? (
          <H4 style={sx({ color:'$primary',marginY:0 })}>{noDataFoundText}</H4>
        ) : (
          noDataFoundText
        )} */}
      </View>
    </View>
  );
};
