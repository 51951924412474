import React from 'react';
import { View, SxProp } from 'dripsy';

export function Container(props: { children: React.ReactNode; sx?: SxProp }) {
  return (
    <View
      sx={{
        px: [20, null, null, null, 60],
      }}
    >
      <View
        {...props}
        sx={{
          width: '100%',
          maxWidth: [640, 768, 1024, 1483],
          mx: 'auto',
          ...props?.sx,
        }}
      >
        {props.children}
      </View>
    </View>
  );
}
