import {
  useSx,
  View,
  Pressable,
  Text,
  ScrollView,
  ActivityIndicator,
} from 'dripsy';
import React, { useState, useEffect, useRef, ReactNode } from 'react';
import {
  Modal,
  Platform,
  TextInputProps,
  View as RNView,
  ViewStyle,
  TextInput,

} from 'react-native';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { makeUseAxiosHook } from 'app/redux/store';
import { useDim } from 'app/redux/hooks/useDim';
import { Icon } from '../icon/icon';
import { click } from '../auto-complete/click/click';
interface SearchBoxProps extends TextInputProps {
  showSearchIcon?: boolean;
  showRightArrowIcon?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  dataList?: [] | ((e?: any) => []);
  styles?: ViewStyle | any[];
  displayKey?: string | ((e: any) => string);
  onChangeTexts?: (d: any) => void;
  onSelectData?: (d: any) => void;
  onEnterClick?: (d?: any) => void;
  Placeholder?: string;
  modalStyle?: ViewStyle;
  loading?: boolean;
  url?: string;
  apiClient?: string;
  searchKey?: string;
  isInitialApiCall?: boolean;
  convertData?: (d: any) => any;
  queryParams?: any;
  replaceUrlObj?: any;
  value?: string;
  placeholderSize?: number;
  IconStyle?: ViewStyle,
  searchIconSize?: number
}
let timeoutId: any;
const debounce = (func: any, delay: any) => {
  return (...args: any[]) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(args[0]);
    }, delay);
  };
};

export function SearchBox({
  showSearchIcon = true,
  showRightArrowIcon = true,
  startIcon,
  endIcon,
  displayKey,
  placeholder,
  onSelectData,
  onEnterClick,
  onChangeTexts,
  modalStyle,
  styles,
  url = '',
  apiClient = '',
  searchKey = 'filterByName',
  isInitialApiCall = false,
  convertData = (d) => [...d.data?.data],
  queryParams = {},
  replaceUrlObj = {},
  value = '',
  placeholderSize,
  IconStyle,
  searchIconSize = 24,
  ...props
}: SearchBoxProps) {
  const [dataList, setDataList] = useState([]);
  const [visible, setVisible] = useState(false);
  const searchRef = useRef<any>();
  const [leftMar, setLeftMar] = useState(0);
  const [dropdownTop, setDropdownTop] = useState(0);
  const [widthLayout, setWidthLayout] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<any>(); //set input ref
  const { height, width } = useDim();
  const sx = useSx();

  const onFocusSearch = () => {
    searchRef.current?.measure(
      (_fx: any, _fy: any, _w: any, h: any, _: any, py: any) => {
        setWidthLayout(_w);
        setLeftMar(_);
        setVisible(true);
        setDropdownTop(Platform.OS === 'android' ? py : py);
      }
    );
  };

  const [touched, setTouched] = useState<any>(null);

  useEffect(() => {
    if (!visible)
      setTimeout(() => {
        click(touched?.x, touched?.y);
      }, 200);
  }, [touched, visible]);

  React.useEffect(() => {
    if (inputRef.current) {
      if (visible) {
        inputRef.current.focus();
      } else {
        inputRef.current.blur();
      }
    }
  }, [inputRef, visible]);

  const onChangeText = (e: string) => {
    debounceSearch(e);
    setInputValue(e);
  };

  const handleOnSearch = (searchInp: string) => {
    if (onChangeTexts) {
      onChangeTexts(searchInp);
    }
  };

  const debounceSearch = debounce(handleOnSearch, 1000);

  const onSubmitClick = (e?: any) => {
    if (onEnterClick) onEnterClick(e);
    setVisible(false);
  };

  const [{ loading }, fetchData] = makeUseAxiosHook(apiClient, {
    manual: true,
  })(
    {
      url,
      method: 'GET',
      params: queryParams,
    },
    replaceUrlObj
  );

  const [onLoad, setOnload] = useState(isInitialApiCall);
  useEffect(() => {
    setOnload(true);
    if (url && apiClient && searchKey) {
      if (onLoad) {
        Promise.resolve(
          fetchData({
            params: inputValue
              ? {
                ...queryParams,
                [searchKey]: inputValue,
              }
              : queryParams,
          })
        ).then((d: any) => setDataList(convertData(d)));
      }
    } else {
      if (props.dataList) setDataList(props.dataList);
    }
  }, [inputValue, props.dataList]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const _RenderList = () => {
    return (
      <Modal
        visible={visible}
        transparent
        animationType="none"
        onRequestClose={() => {
          () => setVisible(false);
        }}
      >
        <Pressable
          style={{
            width: '100%',
            height: '100%',
          }}
          onPressOut={(e) => {
            setVisible(false);
            if (e.nativeEvent.locationX && e.nativeEvent.locationY)
              setTouched({
                x: e.nativeEvent.locationX,
                y: e.nativeEvent.locationY,
              });
          }}
        >
          <View
            style={[
              width > 450
                ? sx({
                  width: widthLayout,
                  top:
                    Platform.OS === 'web'
                      ? dropdownTop
                      : dropdownTop - 24 || 0,
                  backgroundColor: '#FFF',
                  maxHeight: 300,
                  left: leftMar,
                  borderRadius: 5,
                  borderColor: '$secondary',
                  position: 'absolute',
                  paddingTop: 0,
                })
                : sx({
                  width: width,
                  height: height,
                  backgroundColor: '#FFF',
                }),
              modalStyle,
            ]}
          >
            {_SearchBox()}
            <ScrollView
              showsVerticalScrollIndicator={true}
              persistentScrollbar={true}
              keyboardShouldPersistTaps="handled"
              nestedScrollEnabled={true}
              contentContainerStyle={{
                flex: 1,
              }}
            >
              {inputValue && dataList?.map((item: any, i) => (
                <Pressable
                  onPress={() => {
                    setVisible(!visible);
                    if (typeof onSelectData === 'function') onSelectData(item);
                  }}
                  style={[
                    sx({
                      padding: 10,
                      borderBottomWidth: dataList.length - 1 === i ? 0 : 0.5,
                      marginLeft: 10,
                      marginRight: 10,
                      borderBottomColor: '$gray',
                    }),
                  ]}
                  key={`searchKey-${item?._id || i}`}
                >
                  <Text>{displayNameForEachItem(item)}</Text>
                </Pressable>
              ))}
            </ScrollView>
          </View>
        </Pressable>
      </Modal>
    );
  };

  const displayNameForEachItem = (item: any) => {
    if (typeof displayKey === 'function') {
      return displayKey(item);
    } else if (typeof displayKey === 'string') {
      return item[displayKey];
    } else {
      return item;
    }
  };

  const _SearchBox = () => {
    return (
      <View
        style={[
          sx({
            backgroundColor: '#FFF',
            borderColor: '$primary',
            borderWidth: visible ? 0 : 1,
            borderRadius: visible ? 0 : 5,
            borderBottomLeftRadius: visible ? 0 : 5,
            borderBottomRightRadius: visible ? 0 : 5,
            paddingX: 2,
            paddingY: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }),
          { minHeight: 45 },
          450 > width
            ? sx({
              borderColor: '$secondary',
            })
            : {},
          visible && 450 > width
            ? {
              shadowColor: '#171717',
              shadowOffset: { width: -2, height: 4 },
              shadowOpacity: 0.2,
              shadowRadius: 3,
              elevation: 20,
            }
            : {},
          visible && 450 > width ? {} : styles,
        ]}
      >
        {showSearchIcon || startIcon ? (
          startIcon ? (
            startIcon
          ) : (
            <Icon name='searchIcon' fill={['#F47979']} width={searchIconSize} height={searchIconSize} style={[{
              marginLeft: 450 < width ? 12 : 2,
              marginRight: 450 < width ? 21 : 2
            }, IconStyle]} />
          )
        ) : null}

        <Pressable
          onPress={() => {
            onFocusSearch();
          }}
          style={{ flex: 1, justifyContent: 'flex-start' }}
        >
          <TextInput
            placeholder={placeholder}
            onPressIn={() => {
              onFocusSearch();
            }}
            style={[
              { paddingVertical: 8 },
              sx({
                flex: 1,
                color: '#000',
                fontSize: [12, placeholderSize || 0],
                marginLeft: 1,
              }),
              Platform.OS === 'web'
                ? sx({
                  outline: 'none',
                })
                : {},
            ]}
            onChangeText={(e) => {
              onChangeText(e);
            }}
            autoFocus={visible ? true : false}
            ref={inputRef}
            onSubmitEditing={() => {
              onSubmitClick({ name: inputValue });
            }}
            value={inputValue}
            onKeyPress={(event: any) => {
              if (event.nativeEvent.key == 'Enter') {
                onSubmitClick({ name: inputValue });
              }
              if (event?.key === 'ArrowDown') {
              }
              if (event?.key === 'ArrowUp') {
              }
            }}
          />
        </Pressable>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {loading && inputValue ? (
            <ActivityIndicator
              style={sx({ marginRight: 2, marginLeft: 2 })}
              size={20}
              color="rgb(28 118 186 / 50%)"
            />
          ) : (
            <></>
          )}

          {showRightArrowIcon || endIcon ? (
            endIcon ? (
              endIcon
            ) : (
              <MaterialIcons
                name="keyboard-arrow-right"
                size={32}
                style={[
                  sx({
                    color: '$primary',
                  }),
                ]}
              />
            )
          ) : null}
        </View>
      </View>
    );
  };

  const changeLayout = (event: any) => {
    const { width: widths, left, top } = event.nativeEvent.layout;
    setWidthLayout(widths);
    setLeftMar(left);
    setDropdownTop(top);
  };

  return (
    <RNView
      style={[{ flex: 1 }, styles]}
      ref={searchRef}
      onLayout={(event) => {
        changeLayout(event);
      }}
    >

      <Pressable onPress={() => { if (!visible) setVisible(true); }} style={{ flex: 1 }}>
        {_SearchBox()}
      </Pressable>

      {visible && _RenderList()}
    </RNView>
  );
}
