export const checkUserAccess = ({
  user = {},
  permission = [],
  resourceSlug = '',
}: {
  user: any;
  permission: any[];
  resourceSlug: string;
}) => {
  let allowedOBJ = { isAllowed: false, isPermission: 1 };
  let permissionType = 1;
  let allowed = false;

  if (user && user._id) {
    const data =
      permission && Array.isArray(permission)
        ? permission.find((item: any) => item.resourceSlug === resourceSlug)
        : {};

    if (Number(user.role) === 65535 || (data && data.globalAccess)) {
      permissionType = 7;
      allowed = true;
      allowedOBJ = { allowed: true, clientId: user && user.clientId, ...data };
    } else {
      permissionType = data ? data.permission : 1;
      allowed = data ? data.resourceSlug === resourceSlug : false;
      allowedOBJ = data ? { ...data } : { clientId: user && user.clientId };
    }
  }

  const permissionCode = {
    view: [1],
    viewEdit: [3],
    viewEditDelete: [3, 7],
    deleteOnly: [7],
  };

  return {
    allowed,
    permissionType,
    allowedOBJ,
    isSuperAdmin: user?.role ? Number(user.role) === 65535 : false,
    isEdit: permissionCode.viewEditDelete.includes(permissionType),
  };
};
