import { makeUseAxiosHook } from 'app/redux/store';
import { Pressable, Text, useSx, View } from 'dripsy';
import React, { useEffect } from 'react';
import { useRouter } from 'solito/router';
import moment from 'moment';

function NotificationCard({ item, onClose }) {
  const { _id, title, body, createdAt, isSeen, url } = item;
  const sx = useSx();
  const { push } = useRouter();

  const getDate = (dateValue: any) => {
    return moment(dateValue).fromNow();
  };

  const createdDate = getDate(createdAt);

  const [{ data, loading, error }, markAsRead] = makeUseAxiosHook(
    'notification',
    {
      manual: true,
    }
  )(
    {
      url: 'mark-as-read',
      method: 'PATCH',
    },
    {
      notificationId: _id,
    }
  );
  useEffect(() => {
    if (data || (error && !loading)) {
      push(`/${url}`);
      onClose(false);
    }
  }, [data, error]);

  return (
    <View
      style={sx({
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: `${isSeen ? 'white' : '#cae1f4'}`,
        borderRadius: 4,
        marginLeft: 2,
        marginRight:2,
        marginBottom: 1,
        paddingX: 2,
        maxWidth:315
      })}
    >
      <View
        style={sx({
          justifyContent: 'space-between',
          borderBottomWidth: 1,
          borderColor: '$gray',
          minHeight: 60,
          paddingLeft: 0,
          flex: 1,
          flexWrap: 'wrap',
          paddingBottom: 1,
        })}
      >
        <Pressable
          onPress={() => {
            markAsRead();
            if (isSeen) {
              return;
            }
          }}
        >
          <Text
            style={sx({
              fontSize: 16,
              lineHeight: 22,
              marginBottom: 1,
              paddingTop: 2,
            })}
            numberOfLines={2}
            ellipsizeMode="tail"
          >
            {title}
          </Text>
          <Text
            style={sx({
              fontSize: 12,
              paddingBottom: 2,
              lineHeight: 20,
              marginBottom: 1,
            })}
            ellipsizeMode="tail"
          >
            {body}
          </Text>
        </Pressable>
        <Text
          style={sx({
            color: 'grey',
          })}
        >
          {createdDate}
        </Text>
      </View>
    </View>
  );
}

export default NotificationCard;
