import React, { memo } from 'react';
import { Drawer } from '../drawer';
import { Image, View, useSx, ScrollView } from 'dripsy';
// import { MotiLink } from 'solito/moti';
import { DrawerView } from '../menu-list/drawerview';
import { Button } from '../button/common-button';
import { useRouter } from 'solito/router';
import { useCurrentRoute } from 'app/redux/hooks/use-current-route';
import { Platform, Pressable } from 'react-native';
import { Icon } from '../icon/icon';
import { MotiLink } from 'solito/moti';
import { Link } from '../link';
import { useSelector } from 'react-redux';
import { I18Text as Text } from 'app/provider/i18-provider';
import { getClientConfig } from '../../redux/memoizedSelectors';

interface MobileMenuProps {
  showNavBarDrawer: boolean;
  setNavbarDrawer: (data: any) => void;
  user: any;
  permission: any;
  logout?: (navigate: any) => void;
  logoutComponent?:any
}

export const MobileMenu = memo(({
  showNavBarDrawer,
  setNavbarDrawer,
  user,
  permission,
  logout,
  logoutComponent
}: MobileMenuProps) => {
  const { push } = useRouter();
  const { clientConfig } = useSelector(getClientConfig);
  const sx = useSx();
  const currentRoute = useCurrentRoute(true);
  const { footerlistTabs } = clientConfig;

  const shouldDisplayFooter = (key: any) => {
    const items = footerlistTabs?.find((item: any) => item?.key === key);
    return footerlistTabs?.length > 0 ? items ? items?.active ? 'flex' : 'none' : 'flex' : 'flex';
  };

  return (
    <Drawer
      animationIn="bounceInRight"
      animationOut="bounceOutRight"
      isVisible={showNavBarDrawer}
      onClose={() => setNavbarDrawer(false)}
    >
      <View
        style={sx({
          alignItems: 'flex-end',
          paddingX: 3,
          paddingTop: 3,
          paddingBottom: 1,
        })}
      >
        <Pressable onPress={() => setNavbarDrawer(false)}>
          <Icon name="cross" />
        </Pressable>
      </View>

      {!!user && (
        <View
          style={[
            sx({
              flexDirection: 'row',
              alignItems: 'center',
              paddingY: 2,
              paddingX: 3,
            }),
          ]}
        >
          <Image
            source={{ uri: user.avatar }}
            defaultSource={require('../../assets/Enote.svg')}
            resizeMode={'cover'}
            resizeMethod={'scale'}
            style={[
              sx({
                height: [33],
                minWidth: [33],
                borderWidth: 1,
                borderRadius: 33,
                marginRight: 3,
              }),
            ]}
          />
          <MotiLink
            href={`/u/${user && user.username}`}
            style={[
              Platform.select({
                native: sx({
                  minHeight: 20,
                }),
              }),
            ]}
          >
            <Text
              style={[
                sx({
                  fontWeight: 'bold',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  flex: 1,
                  fontSize: 15,
                  lineHeight: 20,
                  color: '$fontGrey',
                }),
              ]}
            >
              {user && user.firstName} {(user && user.lastName) || ''}
            </Text>
          </MotiLink>
        </View>
      )}

      <ScrollView showsHorizontalScrollIndicator={false}>
        <DrawerView
          user={user}
          permission={permission}
          isLanding={!user}
          setNavbarDrawer={(data: boolean) => setNavbarDrawer(data)}
          logout={logout}
          logoutComponent={logoutComponent}
        />
      </ScrollView>

      {!user && (
        <Button
          style={[
            sx({
              height: 37,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: ['$primary', '$secondary'],
              borderRadius: 50,
              padding: 0,
              marginRight: 2,
              marginLeft: 2,
            }),
          ]}
          onPress={() =>
            push(
              `/account/signin${
                currentRoute ? `?continue=${currentRoute}` : ''
              }`
            )
          }
          variantType="outlined"
        >
          Log in
        </Button>
      )}

      <View
        style={[
          sx({
            paddingTop: 2,
            paddingBottom: 4,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 100,
          }),
        ]}
      >
        <Text>
          <Link
            href="/privacy"
            textStyle={sx({
              fontSize: 12,
              color: '$darkSilver',
              letterSpacing: 1,
            })}
            style={{
              margin: 0,
              paddingLeft: 0,
              paddingRight: 0,
              display : shouldDisplayFooter('privacy')
            }}
          >
            Privacy |
          </Link>{' '}
          <Link
            href="/tou"
            textStyle={sx({
              fontSize: 12,
              color: '$darkSilver',
              letterSpacing: 1,
            })}
            style={{
              margin: 0,
              paddingLeft: 0,
              paddingRight: 0,
              display : shouldDisplayFooter('tou')
            }}
          >
            Terms |
          </Link>{' '}
          <Link
            href="/aboutus"
            textStyle={sx({
              fontSize: 12,
              color: '$darkSilver',
              letterSpacing: 1,
            })}
            style={{
              margin: 0,
              paddingLeft: 0,
              paddingRight: 0,
              display : shouldDisplayFooter('aboutUs')
            }}
          >
            About |
          </Link>{' '}
          <Link
            href="/careers"
            textStyle={sx({
              fontSize: 12,
              color: '$darkSilver',
              letterSpacing: 1,
            })}
            style={{
              margin: 0,
              paddingLeft: 0,
              paddingRight: 0,
              display : shouldDisplayFooter('career')
            }}
          >
            Careers
          </Link>
        </Text>
        <Text
          style={[
            sx({
              textAlign: 'center',
              fontSize: 12,
              color: '$darkSilver',
              lineHeight: 15,
              letterSpacing: 1,
            }),
          ]}
        >
          {`${clientConfig?.name} Pvt Ltd. © ${new Date().getFullYear()}`}
        </Text>
      </View>
    </Drawer>
  );
});
