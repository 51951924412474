import React from 'react';
import Modal from 'react-native-modal';
import { useSx } from 'dripsy';
import { ViewStyle } from 'react-native';
import { useDim } from 'app/redux/hooks/useDim';

interface MenuCustomProps {
  onClose: () => any;
  isVisible: boolean;
  children: any;
  animationIn?: any;
  animationOut?: any;
  style?: ViewStyle;
  onBackdropPress?: any;
  onBackButtonPress?: any;
}

export const Drawer = ({
  animationIn = 'bounceInLeft',
  animationOut = 'bounceOutLeft',
  children,
  style,
  onClose,
  onBackdropPress,
  onBackButtonPress,
  isVisible,
  ...props
}: MenuCustomProps) => {
  const sx = useSx();
  const { height } = useDim();
  return (
    <Modal
      useNativeDriver={true}
      onBackButtonPress={onBackButtonPress || (() => onClose && onClose())}
      onSwipeCancel={() => onClose && onClose()}
      onBackdropPress={onBackdropPress || (() => onClose && onClose())}
      isVisible={isVisible}
      style={[
        sx({
          width: 310,
          backgroundColor: '$white',
          margin: 0,
          alignSelf: animationIn === 'bounceInLeft' ? 'flex-start' : 'flex-end',
          justifyContent: 'flex-start',
          minHeight: height,
        }),
        style,
      ]}
      animationIn={animationIn}
      animationOut={animationOut}
      {...props}
    >
      {children}
    </Modal>
  );
};
