import { View, useSx, FlatList } from 'dripsy';
import React, { useEffect, useMemo } from 'react';
import { MotiView } from 'moti';
import { Modal } from 'react-native';
import { MotiPressable, useMotiPressable } from 'moti/interactions';
import { makeUseAxiosHook } from 'app/redux/store';
import { Empty } from '../Empty';
import NotificationCard from './notification-card';
import { Button } from '../button/common-button';
import { I18Text } from 'app/provider/i18-provider';
interface NotificationDropDownProps {
  open: boolean;
  user: any;
  onClose: (e: boolean) => void;
  isConfirmed?: boolean;
  notification?: any;
  getUserNotifications: (userId: any) => void;
  updateUserNotifications: (obj: any) => void;
}

function NotificationDropDown({
  user,
  open,
  onClose,
  notification,
  getUserNotifications,
}: NotificationDropDownProps) {
  const sx = useSx();
  const [{ data: allReadData }, markAllAsRead] = makeUseAxiosHook(
    'notification',
    {
      manual: true,
    }
  )({
    url: 'notification-all-read',
    method: 'POST',
  });

  useEffect(() => {
    if (open || allReadData) {
     if(user) getUserNotifications(user?._id);
    }
  }, [open, allReadData]);

  const unreadNotifications = useMemo(() => {
    return notification?.data?.filter((item: any) => !item?.isSeen) || [];
  }, [notification?.totalNotification]);

  const Item = () => {
    const dropdownState = useMotiPressable(
      'notification',
      ({ hovered, pressed }) => {
        'worklet';

        return {
          opacity: pressed || hovered ? 1 : 0,
          translateY: pressed || hovered ? 0 : -5,
        };
      },
      []
    );
    return (
      <MotiView
        transition={{ type: 'timing', delay: 20, value: {} }}
        state={dropdownState}
        onClick={() => onClose(false)}
      >
        <Modal
          animationType="none"
          transparent={true}
          visible={open}
          onRequestClose={() => onClose(false)}
        >
          <View
            style={[
              sx({
                alignItems: 'flex-end',
                top: 40,
                flex: 1,
                flexWrap: 'wrap',
              }),
            ]}
          >
            <View
              style={[
                sx({
                  margin: 2,
                  marginRight: 3,
                  backgroundColor: 'white',
                  borderRadius: 10,
                  width: 340,
                  maxHeight: [500, 600],
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.25,
                  shadowRadius: 4,
                  elevation: 5,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: ['$primary', '$secondary'],
                  minHeight: [250],
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  paddingBottom: 4,
                }),
              ]}
            >
              <View
                style={sx({
                  paddingTop: 3,
                  paddingX: 3,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                })}
              >
                <I18Text
                  style={[
                    sx({
                      textAlign: 'center',
                      fontSize: 20,
                      fontWeight: '500',
                      paddingLeft: 1,
                    }),
                  ]}
                >
                  Notifications
                </I18Text>

                <Button
                  variantType="text"
                  onPress={() => {
                    if (notification?.totalUnreadMsg > 0) {
                      markAllAsRead({ data: { userId: user._id } });
                    }
                  }}
                  disabled={!unreadNotifications.length}
                  style={{ margin: 0, minWidth: 100 }}
                >
                  <I18Text>Mark as read</I18Text>
                </Button>
              </View>
              <View
                style={sx({
                  paddingTop: 3,
                  flex: 1,
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                })}
              >
                <FlatList
                  scrollEnabled={true}
                  contentContainerStyle={{
                    // flex: 1,
                    minWidth: 320,
                  }}
                  keyExtractor={(item: any) => item._id}
                  numColumns={1}
                  data={notification?.data}
                  renderItem={({ item }: any) => {
                    return <NotificationCard onClose={onClose} item={item} />;
                  }}
                  onEndReachedThreshold={10}
                  ListEmptyComponent={() => (
                    <View
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Empty
                        noDataFoundText={' Waiting for your first notification'}
                      />
                    </View>
                  )}
                />
              </View>
            </View>
          </View>
        </Modal>
      </MotiView>
    );
  };
  return (
    <MotiPressable id="notification">
      <Item />
    </MotiPressable>
  );
}

export default NotificationDropDown;
