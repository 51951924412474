import { I18Text } from 'app/provider/i18-provider';
import React, { useEffect, useRef } from 'react';
import { Animated, StyleSheet, View, ViewProps } from 'react-native';

const BORDER_RADIUS = 2;
const CARET_SIDE_SIZE = 10;
const POPOVER_BACKGROUND_COLOR = 'black';
const POPOVER_FONT_COLOR = 'white';
const POPOVER_FONT_SIZE = 12;
const POPOVER_PADDING = 6;
const POPOVER_WIDTH = 100;

export type PopoverProps = {
  animated?: boolean;
  animationType?: 'spring' | 'timing';
  backgroundColor?: string;
  caret?: boolean;
  caretPosition?: 'left' | 'center' | 'right';
  children: string | React.ReactElement;
  forceInitialAnimation?: boolean;
  numberOfLines?: number;
  visible?: boolean;
  position?: 'top' | 'right' | 'bottom' | 'left';
} & ViewProps;

const ANIMATION_DURATION = 250;

const Popover = React.forwardRef<View, PopoverProps>(function Popover(
  {
    animated = true,
    animationType = 'timing',
    backgroundColor,
    caret: withCaret = true,
    caretPosition = 'center',
    children,
    forceInitialAnimation = false,
    numberOfLines,
    visible = true,
    position = 'bottom',
    style,
    ...extraProps
  },
  ref
) {
  const isContentString = typeof children === 'string';
  const isHorizontalLayout = position === 'left' || position === 'right';
  const prevVisible = useRef(visible);
  const opacity = useRef(
    new Animated.Value(
      visible ? (forceInitialAnimation ? 0 : 1) : forceInitialAnimation ? 1 : 0
    )
  ).current;

  useEffect(
    () => {
      let animation: Animated.CompositeAnimation | undefined;

      if (animated) {
        if (visible && (!prevVisible.current || forceInitialAnimation)) {
          animation = Animated[animationType](opacity, {
            toValue: 1,
            duration: ANIMATION_DURATION,
            useNativeDriver: true,
          });
        } else if (!visible && (prevVisible.current || forceInitialAnimation)) {
          animation = Animated[animationType](opacity, {
            toValue: 0,
            duration: ANIMATION_DURATION,
            useNativeDriver: true,
          });
        }

        animation?.start();
      }

      prevVisible.current = visible;

      return () => animation?.stop();
    },
    [visible] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const caret = (
    <Caret
      align={caretPosition}
      position={position}
      backgroundColor={backgroundColor}
      style={styles.caret}
    />
  );

  let animationTranslation: any;

  if (isHorizontalLayout) {
    animationTranslation = {
      translateX: opacity.interpolate({
        inputRange: [0, 1],
        outputRange: position === 'left' ? [5, 0] : [-5, 0],
      }),
    };
  } else {
    animationTranslation = {
      translateY: opacity.interpolate({
        inputRange: [0, 1],
        outputRange: position === 'top' ? [5, 0] : [-5, 0],
      }),
    };
  }

  return (
    <View
      ref={ref}
      style={[styles.container, style]}
      pointerEvents={visible ? 'auto' : 'none'}
      {...extraProps}
    >
      <Animated.View
        style={[
          { opacity, transform: [animationTranslation] },
          isHorizontalLayout && styles.containerHorizontal,
        ]}
      >
        {withCaret && (position === 'bottom' || position === 'right') && caret}

        <View
          style={[
            styles.content,
            isContentString && styles.contentTextOnly,
            !!backgroundColor && { backgroundColor },
          ]}
        >
          {isContentString ? (
            <I18Text style={styles.contentText} numberOfLines={numberOfLines}>{children}</I18Text>
          ) : (
            children
          )}
        </View>

        {withCaret && (position === 'top' || position === 'left') && caret}
      </Animated.View>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    width: POPOVER_WIDTH,
    overflow: 'hidden',
  },
  containerHorizontal: {
    flexDirection: 'row',
  },
  content: {
    flex: 1,
    zIndex: 1,
    backgroundColor: POPOVER_BACKGROUND_COLOR,
    borderRadius: BORDER_RADIUS * 2,
    overflow: 'hidden',
  },
  contentTextOnly: {
    padding: POPOVER_PADDING,
  },
  contentText: {
    color: POPOVER_FONT_COLOR,
    fontSize: POPOVER_FONT_SIZE,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  caret: {
    zIndex: 0,
  },
});

export type CaretProps = {
  backgroundColor?: string;
  align: 'left' | 'center' | 'right';
  position: PopoverProps['position'];
  style?: ViewProps['style'];
};

export function Caret({ align, backgroundColor, position, style }: CaretProps) {
  return (
    <View
      style={[
        caretStyles.container,
        align === 'center' && caretStyles.containerCenter,
        align === 'right' && caretStyles.containerRight,
        !!backgroundColor && { backgroundColor },
        position === 'top' && caretStyles.containerPositionTop,
        position === 'bottom' && caretStyles.containerPositionBottom,
        position === 'left' && caretStyles.containerPositionLeft,
        position === 'right' && caretStyles.containerPositionRight,
        style,
      ]}
    />
  );
}

const caretStyles = StyleSheet.create({
  container: {
    width: CARET_SIDE_SIZE,
    height: CARET_SIDE_SIZE,
    backgroundColor: POPOVER_BACKGROUND_COLOR,
    transform: [{ rotate: '45deg' }],
    borderRadius: BORDER_RADIUS,
  },
  containerPositionTop: {
    marginTop: (CARET_SIDE_SIZE / 2 + BORDER_RADIUS / 2) * -1,
    marginBottom: CARET_SIDE_SIZE / 2 + BORDER_RADIUS / 2,
  },
  containerPositionBottom: {
    marginBottom: (CARET_SIDE_SIZE / 2 + BORDER_RADIUS / 2) * -1,
    marginTop: CARET_SIDE_SIZE / 2 + BORDER_RADIUS / 2,
  },
  containerPositionLeft: {
    marginLeft: (CARET_SIDE_SIZE / 2 + BORDER_RADIUS / 2) * -1,
    marginRight: CARET_SIDE_SIZE / 2 + BORDER_RADIUS / 2,
  },
  containerPositionRight: {
    marginRight: (CARET_SIDE_SIZE / 2 + BORDER_RADIUS / 2) * -1,
    marginLeft: CARET_SIDE_SIZE / 2 + BORDER_RADIUS / 2,
  },
  containerCenter: {
    alignSelf: 'center',
  },
  containerRight: {
    alignSelf: 'flex-end',
  },
});

export default Popover;
