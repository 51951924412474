import { useSx, View, Row, Text } from 'dripsy';
import React, { useEffect, useState, memo } from 'react';
import { Pressable, ViewStyle, TextStyle, Platform, RotateTransform } from 'react-native';
import { Icon } from '../icon/icon';
import { usePerformance } from 'app/provider/performance';

interface AccordionProps {
  title: any;
  contents?: any;
  containerStyles?: any;
  titleStyles?: TextStyle;
  rowStyle?: ViewStyle;
  contentStyles?: ViewStyle;
  contentTextStyles?: TextStyle;
  isShowIcon?: boolean;
  icon?: React.ReactNode;
  value?: boolean;
  iconFill?: any[];
  children?: any;
  onToggle?: (data: boolean) => any;
  toogleCloseStyle?: any;
}

const isMobile = Platform.OS === 'android';

export function AccordionComp({
  title,
  contents = '',
  containerStyles = {},
  titleStyles = {},
  rowStyle = {},
  contentStyles = {},
  contentTextStyles = {},
  isShowIcon = true,
  icon,
  value = false,
  iconFill = ['#1D76BB'],
  children,
  onToggle,
  toogleCloseStyle
}: Readonly<AccordionProps>) {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setToggle(value);
  }, [value]);
  usePerformance('Accordion');
  const sx = useSx();
  return (
    <View
      style={[
        sx({
          borderRadius: 5,
          boxShadow: 'rgb(213 207 207) 0 3 4 1',
          padding: 12,
          // paddingBottom: 0,
        }),
        !toggle && Platform.select({
          native: toogleCloseStyle
        }),
        containerStyles,
      ]}
    >
      <Pressable
        onPress={() => {
          setToggle(!toggle);
          onToggle && onToggle(!toggle);
        }}
      >
        <Row
          style={[
            sx({
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 0
            }),
            rowStyle,
          ]}
        >
          {typeof title === 'string' ? (
            <Text
              style={[
                sx({
                  fontSize: 16,
                  fontWeight: 'bold',
                }),
                titleStyles,
              ]}
              numberOfLines={isMobile ? 2 : 1}
            >
              {title ? title : 'Title'}
            </Text>
          ) : (
            title
          )}
          {isShowIcon ? (
            <View
              style={{
                transform: [{ rotate: toggle ? '90deg' : '0deg' } as RotateTransform]
              }}
            >
              {icon ? icon : <Icon fill={iconFill} name="right" />}
            </View>
          ) : null}
        </Row>
      </Pressable>
      {toggle ? (
        <View
          style={[
            sx({
              borderRadius: 8,
              paddingTop: 2,
            }),
            contentStyles,
          ]}
        >
          {typeof (contents || children) === 'string' ? (
            <Text
              style={[
                sx({
                  color: 'grey',
                }),
                contentTextStyles,
              ]}
            >
              {contents || children || 'Contents...'}
            </Text>
          ) : (
            contents || children
          )}
        </View>
      ) : (
        <></>
      )}
    </View>
  );
}
export const Accordion = memo(AccordionComp);