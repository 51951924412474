import {
  View,
  useSx,
  SafeAreaView,
  Text,
  Pressable,
  useDripsyTheme,
  Image,
  ActivityIndicator,
} from 'dripsy';
import React, { useState, useMemo, useContext, useEffect } from 'react';
import { Platform, InteractionManager, TouchableOpacity,Linking } from 'react-native';
import { Button } from '../button/common-button';
import { Icon } from '../icon/icon';
import ProfileDropDown from './profile-dropdown';
import { useRouter } from 'solito/router';
import { SearchBox } from '../search';
import { useCurrentRoute } from 'app/redux/hooks/use-current-route';
import NotificationDropDown from './notification-dropdown';
import { materialName, serializeObjectToQuery } from 'app/util/helper';
import { MobileMenu } from './mobile-menu';
import { config } from 'app/redux/config';
import { useDim } from 'app/redux/hooks/useDim';
import { Link } from '../link';
import { DropDown } from 'app/components/dropdown';
import { I18Context, I18Text } from 'app/provider/i18-provider';
import { connect, useDispatch } from 'react-redux';
import * as globalAction from 'app/redux/actions/global.action';
import { getGlobalStore } from '../../redux/memoizedSelectors';
import { Portal } from '../portal';
import { useAlert } from '../../redux/hooks/alert';
import { LOGOUT_SUCCESS } from '../../redux/constants';
import Tooltip from '../tooltip';
export interface NavBarProps {
  user?: any;
  logout?: (navigate: any) => void;
  permission?: any;
  wallet: any;
  notification: any;
  showSearchBox?: boolean;
  getUserNotifications: (userId: any) => void;
  updateUserNotifications: (obj: any) => any;
  searchDefaultValue: any;
  showNavbarBorder?: boolean;
  loggedInNavbar?: boolean;
  navBarSearchBoxPlaceholder?: string;
  clientConfig: any
  updateGlobalStoreData: (obj: any) => any;
  globalStore: any
}
function NavbarComponent({
  user,
  logout,
  permission,
  // wallet,
  notification,
  getUserNotifications,
  updateUserNotifications,
  showSearchBox,
  searchDefaultValue,
  showNavbarBorder = true,
  loggedInNavbar = true,
  // navBarSearchBoxPlaceholder,
  clientConfig,
  globalStore,
  updateGlobalStoreData
}: Readonly<NavBarProps>) {
  const sx = useSx();
  const Router = useRouter();
  const currentRoute = useCurrentRoute(true);
  const { setLang } = useContext(I18Context);
  const [showNavBarDrawer, setNavbarDrawer] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [locale, setLocale] = useState<any>(globalStore?.locale);
  const { width } = useDim();
  const { theme }: any = useDripsyTheme();
  const { width: screenWidth } = useDim();
  const footerlistTabs = clientConfig?.footerlistTabs || [];
  const Alert = useAlert();
  const dispatch = useDispatch();
  const isMobile = useMemo(() => {
    return Platform.OS === 'android' || screenWidth < 400;
  }, [screenWidth]);
const [loading,setLoading] =useState(false);
  const { menulistTabs, languages } = clientConfig || {};

  const redirectTo = (material: any) => {
    let url = '';
    const { _id } = material || { _id: '' };
    const splitId =
      _id?.split('_')?.length > 1 ? _id?.split('_')[1] : _id?.split('_')[0];
    if (material) {
      if (material?.type === 'note' && material?.note) {
        const { institute, specialization, subject, topics } = material?.note;
        url = `${url}${serializeObjectToQuery({
          institute,
          specialization,
          subject,
          topic: topics?._id,
          semester: material?.syllabus?.length && material?.syllabus[0]?.academicSession
        })}`;
      } else if (material?.type === 'note' && material?.syllabus?.length) {
        const { institute, specialization, subject, topics, academicSession } =
          material?.syllabus[0];
        url = `${url}${serializeObjectToQuery({
          institute,
          specialization,
          subject,
          topic: topics?._id || material?.topic[0]?._id,
          semester: academicSession
        })}`;
      } else if (
        (material?.type === 'pyq' || material?.type === 'pyq_analysis') &&
        material?.syllabus
      ) {
        const { instituteId, specializationId, subjectId, yearId, academicSessionId } =
          material?.syllabus;
        url = `${url}${serializeObjectToQuery({
          institute: instituteId?.length ? instituteId[0]?._id : null,
          specialization: specializationId?.length
            ? specializationId[0]?._id
            : null,
          subject: subjectId?.length ? subjectId[0]?._id : null,
          year: yearId?._id,
          semester: academicSessionId?.length && academicSessionId[0]?._id || null
        })}`;
      } else if (
        material?.subject?.length &&
        material?.institute?.length &&
        material?.topic?.length
      ) {
        url = `${url}${serializeObjectToQuery({
          institute: material?.institute[0]?._id,
          subject: material?.subject[0]?._id,
        })}`;
      }
    }
    Router.push(`/m/${splitId}${url}`);
  };


  const shouldDisplayFooter = (key: any) => {
    const items = footerlistTabs?.find((item: any) => item?.key === key);
    return footerlistTabs?.length > 0 ? items ? items?.active ? 'flex' : 'none' : 'flex' : 'flex';
  };

  useEffect(() => {
    if (locale && updateGlobalStoreData) {
      setLang(locale);
      updateGlobalStoreData({
        locale
      });
    }
  }, [locale]);
  
  const handelHelpButton=()=>{
    const url =
    'https://s3.ap-south-1.amazonaws.com/uploadpreprod/1724757534901-User Training Manual for LMS Platform.pdf';
    if(typeof window !== 'undefined' && Platform.OS === 'web') window.open(url, '_blank');
    else {
      Linking.openURL(url);
    }
  };

  return (
    <View
      style={[
        sx({
          backgroundColor: '$white',
          height:
            loggedInNavbar || width < 600
              ? 70
              : 116 + (Platform.OS === 'web' ? 6 : 40),
          borderBottomColor: '$gray',
          borderBottomWidth: showNavbarBorder ? 1 : 0,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          flex: 1,
          paddingRight: [0, 3],
          position: 'absolute',
          zIndex: 9999,
          justifyContent: 'space-between',
          paddingBottom: 10,
          paddingTop: 10,
          ...Platform.select({
            ios: {
              paddingTop: 0,
            }
          })
        }),
      ]}
    >

      <View
        style={sx({
          flexDirection: 'row',
          alignItems: 'center',
          flex: 1,
        })}
      >
        <Link
          href={
            user ? '/dashboard' : Platform.OS === 'web' ? '/' : '/account/signin'
          }
          isNextLink={true}
          style={[
            Platform.select({
              default: sx(
                loggedInNavbar || width < 600
                  ? {
                    alignItems: 'center',
                    paddingLeft: [1, 3],
                    paddingRight: 2,
                    justifyContent: 'center',
                    paddingTop: 0,
                  }
                  : {
                    alignItems: 'center',
                    marginLeft: 4,
                  }
              ),
              native: sx({
                minWidth: loggedInNavbar || width < 600 ? 151 : 206,
                minHeight: loggedInNavbar || width < 600 ? 47 : 147,
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                paddingTop: 0,
                backgroundColor: '$white',
                paddingX: 0,
                paddingY: 0,
                borderRadius: 0,
                margin: 0
              }),
              ios: sx({
                minWidth: 130,
                height: '100%',
              }),
            }),
          ]}
        >
          {!!clientConfig?.logo && (typeof clientConfig?.logo === 'string') && <Image
            resizeMode={'contain'}
            style={
              (loggedInNavbar || width < 600)
                ? {
                  height: 47,
                  width: 121,
                  padding: 0,
                  margin: 0
                }
                : {
                  width: 206,
                  height: 147,
                }
            }
            width={loggedInNavbar || width < 600 ? 121 : 206}
            height={loggedInNavbar || width < 600 ? 47 : 147}
            source={{
              uri: clientConfig?.logo
            }}
          />}
        </Link>
        {showSearchBox && width > 600 && (
          <View
            style={[
              sx({
                marginLeft: [10],
                maxWidth: [0, 300, 340, 340, 666],
                display: ['none', 'none', 'none', 'flex'],
                flexGrow: 1,
                flex: 1,
              }),
            ]}
          >
            <SearchBox
              url="search"
              apiClient="search"
              searchKey="keywords"
              showRightArrowIcon={false}
              styles={[
                sx({
                  borderTopLeftRadius: 30,
                  borderTopRightRadius: 30,
                  borderBottomRightRadius: 30,
                  borderBottomLeftRadius: 30,
                  minHeight: 40,
                  borderColor: '$gray',
                  paddingTop: 0,
                  paddingBottom: 0,
                  boxShadow: '0px 0px 3px 0px #0000001A',
                }),
              ]}
              placeholderSize={15}
              modalStyle={sx({
                borderTopLeftRadius: 15,
                borderTopRightRadius: 15,
                borderBottomRightRadius: 15,
                borderBottomLeftRadius: 15,
                borderColor: '$gray',
              })}
              IconStyle={sx({
                paddingRight: 2,
                fontSize: 24,
              })}
              placeholder={`Search Topic/ Chapter/ ${clientConfig?.academicSetting?.semester}/ ${clientConfig?.academicSetting?.university}`}
              convertData={(d: any) =>
                d.data?.data.map((r: any) => ({
                  ...r,
                  ...r._source,
                  note: r?.inner_hits?.syllabus?.hits?.hits[0]?._source,
                }))
              }
              value={searchDefaultValue}
              onSelectData={(e: any) => redirectTo(e)}
              onEnterClick={(e: any) => {
                if (e?.name?.length) {
                  Router.push(`/search/${encodeURIComponent(e?.name)}`);
                }
              }}
              displayKey={(material) => materialName(material)}
            />
          </View>
        )}
      </View>

      <View
        style={sx({
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          alignItems: 'center',
          // maxWidth: [250, 400],
        })}
      >
        {
          clientConfig?.billing &&
          (<Button
            style={sx({
              borderRadius: 50,
              display: ['none', 'none', 'none', 'flex'],
              borderColor: '$gray',
              marginRight: 3,
              flex: 1
            })}
            textColor="black"
            textStyle={sx({
              color: '$fontGrey',
            })}
            startIcon={<Icon name="prime" height={22} width={26} />}
            variantType="outlined"
            onPress={() => Router.push('/client-subscription')}
          >{menulistTabs?.Subscription?.displayName || 'Subscription'}</Button>)
        }

        {!user && (
          <Button
            variantType="text"
            onPress={() => Router.push('/aboutus')}
            style={sx({ marginRight: 2, display: ['none', 'none', shouldDisplayFooter('aboutUs')] })}
            textStyle={sx({
              color: '$fontGrey',
              ...(!loggedInNavbar
                ? { fontSize: theme?.text?.homeBtnText }
                : {}),
            })}
          >
            About Us
          </Button>
        )}

        {user?._id && (
          <>
            {languages && (
              <DropDown
                iconStyle={{ color: 'black', fontSize: 18 }}
                style={sx({
                  color: '$fontGrey',
                  fontSize: 15,
                })}
                data={languages}
                displayKey={'languageName'}
                placeholder="Language"
                onSelectData={(selectData) => {
                  setLocale(selectData.localeName);
                }}
                selectedData={languages.filter((item: any) => item?.localeName === globalStore?.locale)[0]}
                containerStyle={sx({
                  borderColor: '$lightGrey',
                  backgroundColor: '$white',
                  maxHeight: [40, 30, 30],
                  maxWidth: [90, 100, 200],
                })}
                mainContainerStyle={sx({
                  flex: [1],
                  minWidth: [100, 100, 143],
                  maxWidth: [100, 100, 143],
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingRight: 1,
                })}
              />
            )}
            {(clientConfig?.lnMarketPlaceTabs?.userUpload ||
              user?.role === 65535) && (
                <Button
                  style={sx({
                    borderRadius: 50,
                    display: ['flex', 'none', 'none', 'flex'],
                    borderColor: '$gray',
                    marginRight: [1, 3],
                  })}
                  textColor="black"
                  textStyle={sx({
                    color: '$fontGrey',
                  })}
                  startIcon={<Icon name="upload" height={22} width={26} fill={['$primary']} />}
                  variantType="outlined"
                  onPress={() => Router.push('/upload')}
                >
                  <I18Text label={menulistTabs?.Upload?.displayName || 'Upload'}></I18Text>
                </Button>
              )}
            {
              (clientConfig?.menulistTabs?.Wallet?.active || user?.role === 65535) && (
                <Button
                  onPress={() => Router.push(`/wallet/uploader/${user?.username}`)}
                  style={[
                    sx({
                      marginRight: 2,
                      position: 'relative',
                      display: ['none', 'none', 'flex'],
                    }),
                  ]}
                  variantType={'text'}
                  startIcon={
                    <Icon
                      name={'wallet'}
                      height={25}
                      width={25}
                      fill={[theme?.colors['$primary']]}
                    />
                  }
                />
              )
            }

            {
              // (clientConfig?.menulistTabs?.Wallet?.active || user?.role === 65535) && (
                <Button
                  // onPress={handelHelpButton}
                  style={[
                    sx({
                      marginRight: 2,
                      position: 'relative',
                      display: [ 'flex'],
                    }),
                  ]}
                  variantType={'text'}
                  startIcon={
                    <Tooltip
                    content={'Help'}
                    position="bottom"
                    style={sx({
                      maxWidth: 45,
                    })}
                    onPress={handelHelpButton}
                  >
                    <Icon
                      name={'help'}
                      height={30}
                      width={30}
                      fill={[theme?.colors['$primary']]}
                    />
                    </Tooltip>
                  }
                />
              // )
            }
            {/* need to implement notification */}
            {/* <Pressable
              onPress={() => {
                setNotificationOpen(!notificationOpen);
              }}
              style={[sx({ marginRight: 2 })]}
            >
              <Icon
                name="notification"
                height={25}
                width={25}
                fill={[theme?.colors['$primary']]}
              />
              {(notification?.totalUnreadMsg || 0)  > 0 ? (
                <View
                  style={[
                    sx({
                      backgroundColor: '$red',
                      borderRadius: 50,
                      width: 21,
                      height: 21,
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      top: -9,
                      right: -9,
                    }),
                  ]}
                >
                  <Text
                    style={
                      isMobile ? sx({
                        color: '$white',
                        fontSize: 10,
                        fontWeight: '200',
                      }) : [
                        sx({
                          color: '$white',
                          fontSize: 12,
                          fontWeight: '600',
                        }),
                      ]}
                  >
                    {notification?.totalUnreadMsg <= 9
                      ? notification?.totalUnreadMsg
                      : '9+'}
                  </Text>
                </View>
              ) : (
                <></>
              )}
            </Pressable> */}
            {/* need to implement notification */}
            <NotificationDropDown
              open={notificationOpen}
              user={user}
              onClose={setNotificationOpen}
              notification={notification}
              getUserNotifications={getUserNotifications}
              updateUserNotifications={updateUserNotifications}
            />
          </>
        )}
        <View
          style={[
            sx({
              display: 'flex',
              flexDirection: 'column',

            }),
          ]}
        >
          <SafeAreaView style={sx({ display: ['none', 'none', 'flex'] })}>
            {user?._id ? (
              <>
                <Button
                  style={[sx({ marginLeft: 2, position: 'relative' })]}
                  variantType={'text'}
                  startIcon={
                    <Image
                      source={{ uri: user?.avatar }}
                      defaultSource={require('../../assets/exam-genie-1x.webp')}
                      resizeMode={'cover'}
                      resizeMethod={'scale'}
                      style={[
                        sx({
                          width: 35,
                          height: 35,
                          borderRadius: 35,
                          backgroundColor: '$white',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }),
                      ]}
                    />
                  }
                  variantColor="primary"
                  onPress={() => setOpen(!open)}
                ></Button>

                <ProfileDropDown
                  open={open}
                  user={user}
                  onClose={setOpen}
                  logout={logout}
                />
              </>
            ) : (
              <></>
            )}

            {!user ? (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Button
                  style={[
                    sx({
                      borderRadius: 50,
                      marginRight: 2,
                    }),
                  ]}
                  animate={({ hovered, pressed }) => {
                    'worklet';
                    return {
                      backgroundColor:
                        hovered || pressed
                          ? theme?.colors['$cultured']
                          : theme?.colors['$white'],
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingLeft: 0,
                      paddingBottom: 0,
                    };
                  }}
                  contentStyle={sx({
                    paddingLeft: 3,
                  })}
                  onPress={() => {
                    Router.push(
                      `/account/signin${currentRoute ? `?continue=${currentRoute}` : ''}`,
                      '/account/signin',
                      { shallow: true }
                    );
                  }}
                  variantType="text"
                  textStyle={sx({
                    color: '$fontGrey',
                    ...(!loggedInNavbar
                      ? { fontSize: theme?.text?.homeBtnText }
                      : {}),
                  })}
                  endIconStyle={sx({
                    marginRight: 0,
                  })}
                  endIcon={
                    <View
                      style={sx({
                        marginLeft: 16,
                        borderRadius: 15,
                        backgroundColor: '$primary',
                        height: 30,
                        width: 30,
                        justifyContent: 'center',
                        alignItems: 'center',
                      })}
                    >
                      <Icon
                        height={14}
                        width={14}
                        fill={[theme?.colors['$white']]}
                        name="arrowRight"
                      />
                    </View>
                  }
                >
                  Sign In
                </Button>

                {clientConfig?.registration ? <Button
                  variantType="bubble-expand"
                  variantColor="primary"
                  iconColor="white"
                  style={sx({
                    backgroundColor: '$cultured',
                  })}
                  textColor="black"
                  textStyle={sx({
                    padding: 0,
                    marginRight: 40,
                    ...(!loggedInNavbar
                      ? { fontSize: theme?.text?.homeBtnText }
                      : {}),
                  })}
                  onPress={() =>
                    Router.push(`${config.baseUrl}/account/signup?continue=/`)
                  }
                  endIcon={
                    <Icon
                      height={14}
                      width={14}
                      fill={[theme?.colors['$white']]}
                      name="arrowRight"
                    />
                  }
                >
                  Register
                </Button> : <></>}
              </View>
            ) : (
              <></>
            )}
          </SafeAreaView>

          <Pressable
            onPress={() => {
              InteractionManager.runAfterInteractions(() => {
                setNavbarDrawer(true);
              });
            }}
            style={sx({ display: ['flex', 'flex', 'none'], mr: 20 })}
          >
            <Icon
              name="menu"
              fill={[theme?.colors['$primary']]}
              height={30}
              width={30}
            />
          </Pressable>
          <Portal>
            {showNavBarDrawer && 
            <MobileMenu
              permission={permission}
              showNavBarDrawer={showNavBarDrawer}
              user={user}
              setNavbarDrawer={setNavbarDrawer}
              logout={logout}
              logoutComponent={()=><LogOut setNavbarDrawer={setNavbarDrawer} dispatch={dispatch} sx={sx} logout={logout} loading={loading} clientConfig={clientConfig} setLoading={setLoading} Alert={Alert} />}
            />}
          </Portal>

          {/* Mobile Menu */}
        </View>
      </View>
    </View>
  );
}

// const mapStateToProps = ({ globalStore }: any) => ({
//   globalStore
// });

export const NavBar = connect(getGlobalStore, {
  ...globalAction
})(NavbarComponent);

const LogOut = ({sx,Alert, logout, loading, setLoading , clientConfig, dispatch , setNavbarDrawer})=>{
  return (
    <TouchableOpacity
    // variantType="menu"
    onPress={() => {
      setNavbarDrawer(false);
      Alert({
        buttons: [
          {
            name: 'Cancel',
          },
          {
            name: 'Confirm',
            buttonStyle: { minWidth: 100 },
            onPress: () => {
              if (Platform.OS !== 'web') {
                setTimeout(() => {
                  logout();
                  dispatch({
                    type: LOGOUT_SUCCESS,
                  });
                  setLoading(false);
                }, 10);
              } else {
                if (logout)
                  logout(() => {
                    setLoading(false);
                  });
              }
            },
          },
        ],
        message:
          'Are you sure you want to log out of this device? We will miss you. But you can always log back in at any time.',
        title: `Log out of ${clientConfig?.name}?`,
        type: 'warn',
      });
    }}
    style={[
      sx({
        flexDirection: 'row',
        alignItems: ['center'],
        // marginY: [0, 2],
        paddingY: [1, 0],
        marginLeft: 3,
      }),
    ]}
    disabled={loading}
  >
    <View
      style={sx({
        minWidth: [50, 65],
        alignItems: 'center',
      })}
    >
      <View
        style={[
          sx({
            borderWidth: 2,
            borderColor: ['#F3F1FA', '#E2E7EA'],
            backgroundColor: ['#F3F1FA'],
            padding: [1, 2],
            borderRadius: [20, 13],
            minWidth: [35, 41],
            maxWidth: [35, 41],
            minHeight: [35, 41],
            justifyContent: 'center',
            alignItems: 'center',
          }),
        ]}
      >
        {loading ? (
          <ActivityIndicator color="rgb(28 118 186 / 50%)" />
        ) : (
          <Icon name={'logout'} fill={['$primary']} />
        )}
      </View>
    </View>
    <I18Text
      style={[
        sx({
          color: '$fontGrey',
          fontSize: [13, 15],
          textAlign: 'left',
          width: '100%',
        }),
      ]}
    >
      Logout
    </I18Text>
  </TouchableOpacity>
  )
}