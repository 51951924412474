import {
  useSx,
  View,
  useDripsyTheme,
  ActivityIndicator,
  FlatList,
} from 'dripsy';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ViewProps,
  Platform,
  Linking,
  TouchableOpacity,
  InteractionManager,
  Dimensions
} from 'react-native';
import { MenuList } from './menulist';
import { useCurrentRoute } from 'app/redux/hooks/use-current-route';
import { checkUserAccess } from 'app/redux/hooks/check-user-access';
import { Icon } from '../icon/icon';
import { Accordion } from '../accordion';
import { useRouter } from 'solito/router';
import { useAlert } from 'app/redux/hooks/alert';
import { Button } from '../button/common-button';
import Tooltip from '../tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT_SUCCESS } from 'app/redux/constants';
import { checkUndefined } from 'app/util/helper';
import { I18Text } from 'app/provider/i18-provider';
import { getClientConfig } from '../../redux/memoizedSelectors';
import { createParam } from 'solito';
interface UserObj {
  avatar: string;
  firstName: string;
  lastName: string;
  headline: string;
  role: string;
  username?: string;
}
interface DrawerViewProps extends ViewProps {
  user: UserObj;
  permission: any[];
  leftMenuProps?: any;
  isLanding?: boolean;
  setNavbarDrawer?: (data: boolean) => any;
  logout?: any;
  globalStore?: any;
  updateGlobalStoreData?: (minimize: any) => void;
  logoutComponent?:any
}
const { useParam } = createParam<{ userName?: string }>();

function DrawerViewComp({
  user,
  permission,
  leftMenuProps = {},
  setNavbarDrawer,
  logout,
  globalStore,
  updateGlobalStoreData,
  logoutComponent
  
}: Readonly<DrawerViewProps>) {
  const dispatch = useDispatch();
  const { menuList, showAdminConsole = true } = leftMenuProps;
  const sx = useSx();
  const { clientConfig } = useSelector(getClientConfig);
  const minimizeDashboardMenu = globalStore?.minimizeDashboardMenu;
  const Alert = useAlert();
  const [loading, setLoading] = useState<boolean>(false);
  const { height } = Dimensions.get('window');

  const isAdmin = useMemo(() => {
    return (
      checkUserAccess({
        user,
        permission,
        resourceSlug: 'accounts_client_management',
      }).allowed ||
      checkUserAccess({ user, permission, resourceSlug: 'core_admin_manage' })
        .allowed
    );
  }, [user, permission]);

  const adminConsoleMenu: any[] = useMemo(
    () => ([
      Platform.OS === 'web' &&
      checkUndefined(clientConfig?.showAdminConsole, true)
        ? {
            link: `//admin.${
              typeof window !== 'undefined' &&
              window.location.hostname.replace('www.', '')
            }`,
            name: 'Admin Console',
            icon: 'console',
            isNextLink: false,
            showForLoggedUser: true,
            linkProps: {
              href: `//admin.${
                typeof window !== 'undefined' &&
                window.location.hostname.replace('www.', '')
              }`,
            },
          }
        : {},
    ]),
    [clientConfig?.showAdminConsole]
  );

  const MinimizeIcon = useCallback(() => {
    return (
      <View
        style={sx({
          minWidth: [50, 65],
          alignItems: 'center',
        })}
      >
        <View
          style={[
            sx({
              borderWidth: 2,
              borderColor: ['#F3F1FA', '#E2E7EA'],
              backgroundColor: ['#F3F1FA'],
              padding: [1, 2],
              borderRadius: [20, 13],
              minWidth: [35, 41],
              maxWidth: [35, 41],
              minHeight: [35, 41],
              justifyContent: 'center',
              alignItems: 'center',
            }),
          ]}
        >
          <Icon
            name={minimizeDashboardMenu ? 'maximize' : 'minimize'}
            fill={['$primary']}
          />
        </View>
      </View>
    );
  }, [minimizeDashboardMenu]);
  const [userName] = useParam('userName');
  const [_menuList, setMenuList] = useState(menuList || MenuList({user, permission, menulistTabs: clientConfig.menulistTabs, userName}));
  useEffect(() => {
    setMenuList(menuList || MenuList({user, permission, menulistTabs: clientConfig.menulistTabs, userName}));
  }, [menuList, userName])

  return (
    <View
      style={[
        sx({
          flex: 1,
          flexDirection: 'column',
          backgroundColor: ['$white', '$white', '#F3F1FA'],
          paddingRight: ['$0', '$1'],
          paddingBottom: [1, 4],
          width: minimizeDashboardMenu ? 90 : ['100%', '100%', 312],
          borderRightColor: '#C9C9C9',
          borderRightWidth: [0, 0, 1],
          justifyContent: 'space-between',
          height:height-100,
        }),
      ]}
    >
      <View
        style={[sx({
          paddingTop: [1, 4],
          flex:1
        }),
        Platform.OS === 'web' ?
        sx({
          height:height * 0.75,
        }) : {}
      ]}
      >
        <Menu
          listData={_menuList}
          user={!!user}
          setNavbarDrawer={setNavbarDrawer}
          minimize={minimizeDashboardMenu}
        />

        <View
          style={sx({
            display: ['flex', 'flex', 'none'],
            paddingX: 0,
          })}
        >
          {user &&
            (typeof clientConfig?.logout === 'undefined' ||
              clientConfig?.logout) && logoutComponent && 
               logoutComponent()
            }
        </View>
      </View>
      <View
        style={sx({
          display: ['none', 'none', 'flex'],
        })}
      >
        {showAdminConsole && isAdmin && Platform.OS === 'web' ? (
          <Menu
            listData={adminConsoleMenu}
            user={!!user}
            minimize={minimizeDashboardMenu}
          />
        ) : (
          <></>
        )}
        <Button
          loading={loading}
          variantType="menu"
          onPress={() => {
            updateGlobalStoreData &&
              updateGlobalStoreData(!minimizeDashboardMenu);
          }}
          // animate={({ hovered, pressed }) => {
          //   return {
          //     opacity: hovered || pressed ? 0.7 : 1,
          //   };
          // }}
          style={[
            sx({
              flexDirection: 'row',
              alignItems: 'center',
              marginY: ['$0', '$2'],
              paddingY: ['$2', '$0'],
              display: ['none', 'none', 'flex'],
            }),
            minimizeDashboardMenu &&
              sx({
                maxWidth: 80,
              }),
          ]}
          startIcon={
            minimizeDashboardMenu ? (
              <Tooltip
                content={'Maximize'}
                position="right"
                style={sx({
                  maxWidth: 85,
                })}
                onPress={() =>
                  updateGlobalStoreData &&
                  updateGlobalStoreData(!minimizeDashboardMenu)
                }
              >
                <MinimizeIcon />
              </Tooltip>
            ) : (
              <MinimizeIcon />
            )
          }
          containerStyle={sx({
            alignItems: 'flex-start',
          })}
        >
          <I18Text
            style={[
              sx({
                color: '$fontGrey',
                fontSize: [13, 15],
                textAlign: 'left',
              }),
              minimizeDashboardMenu &&
                sx({
                  display: 'none',
                }),
            ]}
          >
            Minimize
          </I18Text>
        </Button>
      </View>
    </View>
  );
}

const Menu = memo(
  ({
    listData,
    user: _user,
    setNavbarDrawer,
    minimize,
  }: {
    listData: any[];
    user: any;
    setNavbarDrawer?: (data: boolean) => any;
    minimize: boolean;
  }) => {

    const renderItem = ({ item }) => (
      <MenuItemWrapper
        item={item}
        _user={_user}
        setNavbarDrawer={setNavbarDrawer}
        minimize={minimize}
      />
    );

    return (
      <FlatList
        data={listData}
        renderItem={renderItem}
      />
    );
  }
);
const MenuItemWrapperUnmemo =  ({ item: menu, _user, setNavbarDrawer, minimize }: any) => {
  const sx = useSx();
  return menu &&
    ((menu.showForLoggedUser === true && _user) ||
      (menu.showForLoggedUser === false && !_user)) ? (
    menu.type && menu.type === 'divider' ? (
      <View
        key={`menuKeys-${menu.name.replace(' ', '')}`}
        style={[
          sx({
            ...Platform.select({
              web: {
                marginX: '$3',
                borderBottomColor: '#EAEAEA',
                borderBottomWidth: 0.2,
                width: '100%',
                marginTop: 1,
                marginBottom: 1,
                justifyContent: 'center',
                alignItems: 'center',
                display: menu.showOnlyMobile
                  ? ['flex', 'flex', 'none']
                  : ['flex'],
              },
              native: {},
            }),
          }),
        ]}
      ></View>
    ) : (
      <View
        style={sx({
          display: [
            'flex',
            'flex',
            `${menu.showOnlyMobile ? 'none' : 'flex'}`,
          ],
        })}
        key={`menuKey-${menu.name.replace(' ', '')}`}
      >
        <MenuItem
          menu={menu}
          setNavbarDrawer={(data: boolean) =>
            setNavbarDrawer && setNavbarDrawer(data)
          }
          minimize={minimize}
        />
      </View>
    )
  ) : null;
};
const MenuItemWrapper = memo(MenuItemWrapperUnmemo);
const MenuItemUnMemo = ({
  menu,
  setNavbarDrawer,
  minimize,
}: {
  menu: any;
  setNavbarDrawer?: (data: boolean) => any;
  minimize: boolean;
}) => {
  const currentRoute = useCurrentRoute();
  return menu.children && menu.children.length && !minimize ? (
    <Accordion
      key={`${menu.name.replace(' ', '')}`}
      title={
        <View>
          <MenuContent menu={menu} minimize={minimize} />
        </View>
      }
      iconFill={['#000000']}
      isShowIcon={minimize ? false : true}
      value={menu.children
        .map((menuLink) => menuLink.link)
        ?.includes(currentRoute)}
      contents={
        <View>
          {menu.children.map((childrenMenu, index) => {
            return (
              <MenuItem
                key={`${childrenMenu.name}-${index}`}
                menu={childrenMenu}
                setNavbarDrawer={(data: boolean) =>
                  setNavbarDrawer && setNavbarDrawer(data)
                }
                minimize={minimize}
              />
            );
          })}
        </View>
      }
      containerStyles={{
        paddingLeft: 0,
        paddingTop: 0,
        marginBottom: 8,
      }}
      toogleCloseStyle={{
        maxHeight: 45,
      }}
    />
  ) : (
    <MenuContent
      menu={menu}
      setNavbarDrawer={setNavbarDrawer}
      minimize={minimize}
    />
  );
};
const MenuItem = memo(MenuItemUnMemo);
const MenuContentUnMemo = ({
  menu,
  setNavbarDrawer,
  minimize,
}: {
  menu: any;
  setNavbarDrawer?: (data: boolean) => any;
  minimize?: any;
}) => {
  const sx = useSx();
  const currentRoute = useCurrentRoute();
  const { push } = useRouter();
  const [activeMenu, setActiveMenu] = useState<boolean>(false);
  const { theme }: any = useDripsyTheme();

  useEffect(() => {
    if (menu.onActiveLinks?.includes(currentRoute) || menu.link === currentRoute) {
      setActiveMenu(true);
    }
  }, [currentRoute]);

  const menuIcon = () => {
    return (
      <View
        style={[
          sx({
            borderWidth: 2,
            borderColor: ['#F3F1FA', '#E2E7EA'],
            backgroundColor: ['#F3F1FA'],
            padding: [1, 2],
            borderRadius: [20, 13],
            minWidth: [35, 41],
            maxWidth: [35, 41],
            minHeight: [35, 41],
            justifyContent: 'center',
            alignItems: 'center',
          }),
          activeMenu &&
            sx({
              backgroundColor: '$primary',
            }),
        ]}
      >
        <Icon
          name={menu.icon}
          fill={
            activeMenu
              ? [theme?.colors['$white']]
              : [theme?.colors['$primary']]
          }
        />
      </View>
    );
  };

  const handleMenuPress = () => {
    if (menu?.children?.length && minimize) {
      push(menu?.linkProps?.href, menu?.linkProps?.as);
      return;
    }
    setNavbarDrawer && setNavbarDrawer(false);
    if (menu?.isNextLink) {
      InteractionManager.runAfterInteractions(() => {
        push(menu?.linkProps?.href, menu?.linkProps?.as);
      });
    } else if (Platform.OS === 'web') {
        window.open(menu?.linkProps?.href, '_self');
      } else {
        Linking.openURL(menu?.linkProps?.href)
      };
  };

  return (
    <Button
      variantType="menu"
      onPress={handleMenuPress}
      key={`menuContent-${menu.name.replace(' ', '')}`}
      style={[
        sx({
          flexDirection: 'row',
          alignItems: 'center',
          marginY: [0, 2],
          paddingY: [1, 0],
          width: ['100%', '100%', 'auto'],
        }),
        activeMenu &&
          sx({
            backgroundColor: ['#F3F1FA'],
          }),
        minimize &&
          sx({
            maxWidth: 80,
          }),
      ]}
      startIcon={
        <View
          style={sx({
            minWidth: [50, 65],
            alignItems: 'center',
          })}
        >
          {menu.icon && (
            <>
              {minimize ? (
                <Tooltip
                  content={menu.name}
                  position="top"
                  style={sx({
                    maxWidth: 85,
                  })}
                  onPress={() => handleMenuPress()}
                >
                  {menuIcon()}
                </Tooltip>
              ) : (
                menuIcon()
              )}
            </>
          )}
        </View>
      }
      containerStyle={sx({
        alignItems: 'flex-start',
        flex: 1,
      })}
      contentStyle={sx({
        justifyContent: ['flex-start', 'flex-start', 'center'],
        color: '$fontGrey',
      })}
      animatePressable={({ pressed, hovered }) => {
        return {
          opacity: pressed || hovered ? 0.5 : 1,
        };
      }}
    >
      <I18Text
        style={[
          activeMenu &&
            sx({
              fontWeight: '600',
            }),
          sx({
            color: '$fontGrey',
            fontSize: [13, 15],
            textAlign: 'left',
          }),
          minimize &&
            sx({
              display: 'none',
            }),
        ]}
      >
        {menu.name}
      </I18Text>
    </Button>
  );
};
const MenuContent = memo(
  MenuContentUnMemo
);

export const DrawerView = memo(DrawerViewComp);
DrawerViewComp.whyDidYouRender = true;
