import { Action, Dispatch } from 'redux';
import { UPDATE_GLOBAL_STORE_DATA } from '../constants';

export const updateGlobalStoreData = (data: any) => {
    return (dispatch: Dispatch): Action =>
      dispatch({
        type: UPDATE_GLOBAL_STORE_DATA,
        payload: data,
      });
  };
