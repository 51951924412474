import React, { useState } from 'react';
import { View, Text, Image, useSx } from 'dripsy';
import { Gradient } from 'dripsy/gradient';
import { A } from 'app/components/typography/a';
import { Icon } from 'app/components/icon/icon';
import { Container } from '../container2';
import { MotiPressable } from 'moti/interactions';

import { StaticFooter } from './static-footer2';
import { config } from 'app/redux/config';
import { useDim } from 'app/redux/hooks/useDim';
import { Link } from '../link';
import { Platform } from 'react-native';

export const Footer = ({ clientConfig }: { clientConfig: any }) => {
  const sx = useSx();
  const { width } = useDim();

  const { footerlistTabs } = clientConfig ?? { footerlistTabs: [] };
  const footerContainer = [
    {
      name: 'Company',
      child: [
        {
          title: 'About Us',
          url: '/aboutus',
          id: 1,
          isNextLink: true,
          key: 'aboutUs'
        },
        {
          title: 'Careers',
          url: '/careers',
          id: 2,
          isNextLink: true,
          key: 'careers'
        },
        {
          title: 'FAQs',
          url: '/faq',
          id: 3,
          isNextLink: true,
          key: 'faqs'
        },
        {
          title: 'Contact Us',
          url: '/contact-us',
          id: 4,
          isNextLink: true,
          key: 'contactUs'
        },
        {
          title: 'Blogs',
          url: config.blogBaseUrl,
          id: 5,
          isNextLink: false,
          key: 'blogs'
        },
      ],
    },
    {
      name: 'Policies',
      child: [
        {
          title: 'Terms',
          url: '/tou',
          id: 1,
          isNextLink: true,
          key: 'terms'

        },
        {
          title: 'Privacy',
          url: '/privacy',
          id: 2,
          isNextLink: true,
          key: 'privacy'

        },
        {
          title: 'Cancellation',
          url: '/returns-and-cancellation',
          id: 3,
          isNextLink: true,
          key: 'cancellation'

        },
        {
          title: 'Copyright',
          url: '/report/copyright',
          id: 4,
          isNextLink: true,
          key: 'copyright'

        },
        {
          title: 'Disclaimer',
          url: '/disclaimer',
          id: 5,
          isNextLink: true,
          key: 'disclaimer'

        },
      ],
    },
  ];

  const [twitterImg, setTwitterImg] = useState(false);

  const [facImg, setFacImg] = useState(false);

  const [LinkImg, setLinkImg] = useState(false);

  const [instaImg, setInstaImg] = useState(false);

  const shouldDisplayFooter = (key: any) => {
    // Find the item in footerlistTabs with the specified key
    const items = footerlistTabs?.find((item: any) => item?.key === key);
    // If the item is found and active, return 'flex'; otherwise, return 'none'
    return footerlistTabs?.length > 0 ? items ? items?.active ? 'flex' : 'none' : 'flex' : 'flex';
  };

  const getValue = (key:any)=>{
     return footerlistTabs?.find((item:any) => item?.key === key)?.value || '';
  };

  const _LogoContainer = () => {
    return (
      <View
        style={sx({
          flex: 1,
          justifyContent: ['space-between'],
          alignItems: 'center',
          flexDirection: ['row', null, 'column'],
          marginTop: [0, 1],
          paddingTop: [0, 1],
        })}
      >
        <MotiPressable
          style={sx({
            width: [50, null, 25],
            height: [50],
            borderRadius: 25,
            justifyContent: ['center'],
            display: shouldDisplayFooter('Twitter')
          })}
          onHoverOut={() => {
            setTwitterImg(false);
            return true;
          }}
          onHoverIn={() => {
            setTwitterImg(true);
            return true;
          }}
        >
          <A href={shouldDisplayFooter('Twitter') === 'flex' ? getValue('Twitter') : 'https://twitter.com/LectureNotesIN'} target="_blank">
            <Icon
              name="twitterNew"
              fill={[width < 768 || twitterImg ? '#4267B2' : '#2F3443']}
              height={25}
              width={25}
            />
          </A>
        </MotiPressable>

        <MotiPressable
          style={[
            sx({
              width: [50, null, 25],
              height: [50],
              alignItems: 'center',
              justifyContent: 'center',
              display: shouldDisplayFooter('instagram')
            }),
          ]}
          onHoverOut={() => {
            setInstaImg(false);
            return true;
          }}
          onHoverIn={() => {
            setInstaImg(true);
            return true;
          }}
        >
          <A
            href={shouldDisplayFooter('instagram') === 'flex' ? getValue('Twitter') :  'https://www.instagram.com/lecturenotes.in/'}
            target="_blank"
          >
            <Icon
              name="instagram"
              fill={
                width < 768 || instaImg
                  ? ['#d62976', '#962fbf', '#fa7e1e', '#feda75']
                  : ['#2F3443']
              }
              height={25}
              width={25}
            />
          </A>
        </MotiPressable>
        <MotiPressable
          style={sx({
            width: [50, null, 25],
            height: [43],
            borderRadius: 25,
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: [0],
            display: shouldDisplayFooter('facebook')
          })}
          onHoverOut={() => {
            setFacImg(false);
            return true;
          }}
          onHoverIn={() => {
            setFacImg(true);
            return true;
          }}
        >
          <A href={shouldDisplayFooter('facebook') === 'flex' ? getValue('facebook') : 'https://www.facebook.com/lecturenotes.in'} target="_blank">
            <Icon
              name="facebook"
              fill={[width < 768 || facImg ? '#4267B2' : '#2F3443']}
              height={25}
              width={25}
            />
          </A>
        </MotiPressable>
        <MotiPressable
          style={sx({
            width: [50, null, 25],
            height: [43],
            borderRadius: 25,
            alignItems: 'center',
            paddingTop: [0],
            marginX: [10],
            justifyContent: 'center',
            display: shouldDisplayFooter('linkdin')
          })}
          onHoverOut={() => {
            setLinkImg(false);
            return true;
          }}
          onHoverIn={() => {
            setLinkImg(true);
            return true;
          }}
        >
          <A
            href={ shouldDisplayFooter('linkedIn') === 'flex' ? getValue('linkedIn') :  'https://www.linkedin.com/company/lecturenotes/mycompany/'}
            target="_blank"
          >
            <Icon
              name="linkedIn"
              fill={[width < 768 || LinkImg ? '#4267B2' : '#2F3443']}
              height={25}
              width={25}
            />
          </A>
        </MotiPressable>
      </View>
    );
  };
  return (
    <View
      style={sx({
        backgroundColor: '#F3F1FA',
        marginX: [3, null, null, null, 4],
        mb: 30,
        borderRadius: [14, 16],
        overflow: 'hidden',
      })}
    >
      <Gradient
        start={[0, 0]}
        end={[1, 1]}
        colors={['#99999910', '#99999910']}
      >
        <Container
          sx={{
            flexDirection: ['column', 'column', 'row'],
            paddingY: [3, 4],
            paddingX: [13, 13, 13, 13, 40],
          }}
        >
          <View
            style={sx({
              flexDirection: 'row',
            })}
          >
            {
              width >= 720 && (
                <View
                  style={sx({
                    display: 'flex',
                  })}
                >
                  {_LogoContainer()}
                </View>
              )
            }

            <View
              style={[
                sx({
                  marginLeft: [0, 0, 4],
                  maxWidth: '100%',
                }),
              ]}
            >
              <Link href={'/dashboard'} isNextLink={true} style={{
                width: 187,
                height: 50,
              }}
              >
                <Image
                  source={{ uri: clientConfig?.logo }}
                  resizeMode={'contain'}
                  resizeMethod={'resize'}
                  style={[
                    sx({
                      minHeight: 50,
                      width: [187],
                      marginTop: [0, 2],
                    })
                  ]}
                />
              </Link>

              <Text
                style={[
                  sx({
                    width: [width - 20, 300, 550],
                    paddingTop: 3,
                    paddingLeft: [0, 0, 2],
                    fontSize: [15, 17],
                    lineHeight: 21,
                    color: '$fontGrey',
                    // display: shouldDisplayFooter('contentDescription')
                  }),
                  Platform.select({
                    native: {
                      width: 280
                    }
                  })
                ]}
                numberOfLines={6}
              >
                {footerlistTabs?.length && shouldDisplayFooter('contentDescription') === 'flex'  ? footerlistTabs?.find((item) => item?.key === 'contentDescription')?.value : (
                  `${clientConfig?.name} is one of India's biggest undergraduate notes marketplaces containing more than 80 lakh pages of student notes, faculty notes, solved previous year question papers, and much more!`
                )}
              </Text>
            </View>
          </View>

          <View
            style={sx({
              flex: 1,
              flexDirection: ['row', null, 'row'],
              justifyContent: ['flex-start', 'space-around'],
              paddingTop: [3, null, 0],
            })}
          >
            {footerContainer.map((item: any) => {
              return (
                <View key={`each-${item.name}`} >
                  <Text
                    style={sx({
                      fontWeight: '700',
                      marginTop: [20, null, null, 0],
                      marginBottom: 10,
                      fontSize: 17,
                      lineHeight: 21,
                    })}
                  >
                    {item?.name}
                  </Text>
                  <View
                    style={{
                      flex: 1,
                      alignItems: 'flex-start',
                    }}
                  >
                    {item.child.map((subChild: any) => (
                      <View
                        key={`${item?.name}-${subChild?.id}`}
                        style={[{
                          marginVertical: 5,
                          display: shouldDisplayFooter(subChild?.key)
                        },
                        ]}
                      >
                        <Link
                          variantType={'text'}
                          style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginTop: 0,
                            marginBottom: 0,
                            minWidth: 150,
                          }}
                          textStyle={[sx({
                            fontSize: [15, 17],
                            fontWeight: '500',
                            lineHeight: 21,
                            color: '#2f3443',
                            width: '100%'
                          }),
                          ]}
                          href={subChild.url}
                          isNextLink={subChild.isNextLink}
                          onHoveredTextColor="#5899E9"
                        >
                          {subChild.title}
                        </Link>
                      </View>
                    ))}
                  </View>
                </View>
              );
            })}
          </View>
          {
            width < 720 && (
              <View
                style={sx({
                  mt: 20,
                  display: 'flex',
                })}
              >
                {_LogoContainer()}
              </View>
            )
          }
        </Container>
      </Gradient>
      <StaticFooter clientName={clientConfig?.name} />
    </View>
  );
};
