import { checkUserAccess } from 'app/redux/hooks/check-user-access';
import { config } from 'app/redux/config';
import Constants from 'expo-constants';

// all dashboard drawer menu list

export const MenuList = ({ user, permission, menulistTabs, userName }: { user: any, permission:any, menulistTabs: any, userName }) => {

  const allowedMenus = Constants?.expoConfig?.extra?.allowedMenus || [];


  // const { menulistTabs } = clientConfig || {};
  const checkAccess = (slug: any) => {
    return checkUserAccess({
      user,
      permission,
      resourceSlug: slug,
    }).allowed ||
      checkUserAccess({ user, permission, resourceSlug: 'core_admin_manage' })
        .allowed;
  };

  const menus = ([
    {
      name: menulistTabs?.Dashboard?.displayName || 'Dashboard',
      icon: 'dashboard',
      link: '/dashboard',
      onActiveLinks: ['/dashboard'],
      isNextLink: true,
      showForLoggedUser: true,
      linkProps: {
        href: '/dashboard',
      },
    },
    // {
    //   name: 'ExamAdda',
    //   icon: 'examAdda',
    //   isNextLink: true,
    //   showForLoggedUser: true,
    //   link: '/dashboard/exam-adda/exam-genie',
    //   linkProps: {
    //     href: '/dashboard/exam-adda/exam-genie',
    //   },
    //   onActiveLinks: [
    //     '/dashboard/exam-adda/exam-genie',
    //     '/dashboard/exam-adda/q-paper',
    //     '/dashboard/exam-adda',
    //     '/dashboard/exam-adda/video-materials',
    //   ],
    //   children: [
    //     {
    //       name: 'Exam Genie',
    //       link: '/dashboard/exam-adda/exam-genie',
    //       onActiveLinks: ['/dashboard/exam-adda/exam-genie'],
    //       linkProps: {
    //         href: '/dashboard/exam-adda/exam-genie',
    //       },
    //       isNextLink: true,
    //       showForLoggedUser: true,
    //     },
    //     {
    //       name: 'Q Paper',
    //       link: '/dashboard/exam-adda/q-paper',
    //       onActiveLinks: ['/dashboard/exam-adda/q-paper'],
    //       linkProps: {
    //         href: '/dashboard/exam-adda/q-paper',
    //       },
    //       isNextLink: true,
    //       showForLoggedUser: true,
    //     },
    //     {
    //       name: 'Videos',
    //       link: '/dashboard/exam-adda/video-materials',
    //       onActiveLinks: ['/dashboard/exam-adda/video-materials'],
    //       linkProps: {
    //         href: '/dashboard/exam-adda/video-materials',
    //       },
    //       isNextLink: true,
    //       showForLoggedUser: true,
    //     },
    //   ],
    // },
    {
      name: menulistTabs?.Exams?.displayName || 'Exams',
      icon: 'examAdda',
      link: '/exams/ongoing',
      onActiveLinks: ['/exams'],
      isNextLink: true,
      showForLoggedUser: true,
      linkProps: {
        href: '/exams/ongoing',
      },
    },
    {
      name: menulistTabs?.Syllabus?.displayName || 'Syllabus',
      icon: 'syllabusTracker',
      link: '/syllabus',
      onActiveLinks: ['/syllabus'],
      isNextLink: true,
      showForLoggedUser: true,
      linkProps: {
        href: '/syllabus',
      },
    },
    {
      name: menulistTabs?.SavedMaterial?.displayName || 'Saved Material',
      icon: 'savedMaterial',
      link: '/saved-material',
      onActiveLinks: ['/saved-material'],
      isNextLink: true,
      showForLoggedUser: true,
      linkProps: {
        href: '/saved-material',
      },
    },
    {
      name:
        menulistTabs?.CourseMarketplace?.displayName || 'Course Marketplace',
      icon: 'marketplaceIcon',
      link: '/job-courses',
      onActiveLinks: ['/job-courses'],
      isNextLink: true,
      showForLoggedUser: true,
      linkProps: {
        href: '/job-courses',
      },
    },

    // only on mobile
    {
      type: 'divider',
      showForLoggedUser: true,
      showOnlyMobile: true,
    },

    {
      name: menulistTabs?.Subscription?.displayName || 'Subscription',
      icon: 'prime',
      link: '/client-subscription',
      isNextLink: true,
      showForLoggedUser: true,
      showOnlyMobile: true,
      linkProps: {
        href: '/client-subscription',
      },
    },
    {
      name: menulistTabs?.Upload?.displayName || 'Upload',
      icon: 'upload',
      link: '/upload',
      isNextLink: true,
      showForLoggedUser: true,
      showOnlyMobile: true,
      linkProps: {
        href: '/upload',
      },
    },
    {
      name: menulistTabs?.Wallet?.displayName || 'Wallet',
      icon: 'wallet',
      link: '/wallet',
      isNextLink: true,
      showForLoggedUser: true,
      showOnlyMobile: true,
      onActiveLinks: [
        `/wallet/subscription/${userName}`,
        `/wallet/uploader/${userName}`,
        `/wallet/refer-earn/${userName}`,
        `/wallet/lca/${userName}`,
      ],
      linkProps: {
        href: '/wallet/[pageType]/[userName]',
        as: `/wallet/uploader/${userName ?? user?.username}`,
      },
    },

    {
      type: 'divider',
      showForLoggedUser: true,
      showOnlyMobile: true,
    },
    {
      name: menulistTabs?.Classroom?.displayName || 'Smart Classroom',
      icon: 'classroom',
      link: '/classroom',
      isNextLink: true,
      showForLoggedUser: true,
      linkProps: {
        href: '/classroom',
      },
    },
    {
      name: menulistTabs?.Report?.displayName || 'Reports',
      icon: 'reportsOutlined',
      link: '/reports',
      isNextLink: true,
      showForLoggedUser: true,
      linkProps: {
        href: '/reports',
      },
    },
    {
      name: menulistTabs?.ReferEarn?.displayName || 'Refer and Earn',
      icon: 'refer',
      link: '/refer-earn',
      isNextLink: true,
      showForLoggedUser: true,
      showOnlyMobile: true,
      linkProps: {
        href: '/refer-earn',
      },
    },
    // {
    //   name: menulistTabs?.CampusAmbassador?.displayName || 'Campus Ambassador',
    //   icon: 'ambassador',
    //   onActiveLinks: [],
    //   link: '/ca',
    //   isNextLink: true,
    //   showForLoggedUser: true,
    //   showOnlyMobile: true,
    //   linkProps: {
    //     href:
    //       user?.role === 3 ? `/wallet/lca/${user.username || userName}` : '/ca',
    //   },
    // },
    {
      type: 'divider',
      showForLoggedUser: true,
      showOnlyMobile: true,
    },
    {
      name: menulistTabs?.Settings?.displayName || 'Settings',
      icon: 'setting',
      onActiveLinks: ['/settings'],
      showOnlyMobile: true,
      link: '/settings',
      isNextLink: true,
      showForLoggedUser: true,
      linkProps: {
        as: `/settings/${
          userName ? userName : user && user?.username
        }/security`,
        href: '/settings/[userName]/[pageType]',
      },
      children: [
        {
          name: 'Security',
          link: `/settings/${
            userName ? userName : user && user?.username
          }/security`,
          onActiveLinks: ['/settings'],
          isNextLink: true,
          showOnlyMobile: true,
          showForLoggedUser: true,
          linkProps: {
            as: `/settings/${
              userName ? userName : user && user?.username
            }/security`,
            href: '/settings/[userName]/[pageType]',
          },
        },
        {
          name: 'Device Management',
          link: `/settings/${
            userName ? userName : user && user?.username
          }/device-management`,
          onActiveLinks: ['/settings'],
          isNextLink: true,
          showOnlyMobile: true,
          showForLoggedUser: true,
          linkProps: {
            as: `/settings/${
              userName ? userName : user && user?.username
            }/device-management`,
            href: '/settings/[userName]/[pageType]',
          },
        },
      ],
    },
    {
      name: menulistTabs?.HelpSupport?.displayName || 'Help & Support',
      icon: 'support',
      link: '/contact-us',
      isNextLink: true,
      showForLoggedUser: true,
      showOnlyMobile: true,
      linkProps: {
        href: '/contact-us',
      },
    },
    // For Non login users
    {
      name: 'About Us',
      icon: 'refer',
      link: '/aboutus',
      isNextLink: true,
      showForLoggedUser: false,
      linkProps: {
        href: '/aboutus',
      },
    },
    {
      name: 'Blogs',
      icon: 'classroom',
      onActiveLinks: [],
      link: config.blogBaseUrl,
      isNextLink: false,
      showForLoggedUser: false,
      linkProps: {
        href: config.blogBaseUrl,
      },
    },
    {
      name: menulistTabs?.Subscription?.displayName || 'Subscription',
      icon: 'prime',
      link: '/client-subscription',
      isNextLink: true,
      showForLoggedUser: false,
      linkProps: {
        href: '/client-subscription',
      },
    },
    {
      name: menulistTabs?.mentorShipProgram?.displayName || 'Mentor ship Program',
      icon: 'mentor',
      link: '/mentor-ship-program',
      isNextLink: true,
      showForLoggedUser: true,
      linkProps: {
        href: '/mentor-ship-program',
      },
    },
    {
      name: menulistTabs?.survey?.displayName || 'Survey',
      icon: 'survey',
      link: '/encounter',
      isNextLink: true,
      showForLoggedUser: true,
      linkProps: {
        href: '/encounter',
      },
    },
    checkAccess('core_training_training_manage') ?
      { // training-management hide for mobile app 
        name: menulistTabs?.trainingManagement?.displayName || 'Training Management',
        icon: 'survey',
        link: '/training-management',
        isNextLink: true,
        showForLoggedUser: true,
        linkProps: {
          href: '/training-management',
        },
      } : {},
    {
      name: menulistTabs?.forum?.displayName || 'Forum',
      icon: 'survey',
      link: '/chat',
      isNextLink: true,
      showForLoggedUser: true,
      linkProps: {
        href: '/chat',
      },
    },
    {
      name: menulistTabs?.course?.displayName || 'Module Structure',
      icon: 'survey',
      link: '/module-structure',
      isNextLink: true,
      showForLoggedUser: true,
      linkProps: {
        href: '/module-structure',
      },
    },
    checkAccess('core_classroom_classroom_manage') ? {
      name: menulistTabs?.course?.displayName || 'Course Management',
      icon: 'survey',
      link: '/courses',
      isNextLink: true,
      showForLoggedUser: true,
      linkProps: {
        href: '/courses',
      },
    } : {},
    {
      name: menulistTabs?.contentRepository?.displayName || 'contentRepository',
      icon: 'search',
      link: '/search',
      isNextLink: true,
      showForLoggedUser: true,
      linkProps: {
        href: '/search',
      },
    },
  ]);


  const activeMenus = Object.entries(menulistTabs || {}).filter(
    ([key, menu]: any) => ((allowedMenus.length && allowedMenus.includes(key))|| !allowedMenus.length) && menu?.active
  ).map(([, menu]: any) => menu);

  const visibleMenus = menus
  .filter((m: any) => activeMenus.some((each: any) => each?.displayName === m.name))
  .sort((a: any, b: any) => {
    const aActiveMenu: any = activeMenus.find((each: any) => each?.displayName === a.name);
    const bActiveMenu: any = activeMenus.find((each: any) => each?.displayName === b.name);
    const aSlNo = aActiveMenu?.slNo ?? 0;
    const bSlNo = bActiveMenu?.slNo ?? 0;
    return aSlNo - bSlNo;
  });

  return visibleMenus;
};
