import { Text, useSx, View } from 'dripsy';
import React from 'react';
import { Platform } from 'react-native';

export function StaticFooter({ clientName }) {
  const sx = useSx();
  return Platform.OS === 'web' ? (
    <View
      style={sx({
        backgroundColor: '$primary',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: [60, 45],
        textAlign: 'center',
      })}
    >
      <Text
        style={sx({ color: '$white', paddingY: 2 })}
      >{`Copyrights @ ${new Date().getFullYear()} ${clientName}`}</Text>
    </View>
  ) : (
    <></>
  );
}
