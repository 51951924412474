import { NavBar } from 'app/components/navbar';
import { ScrollView, useSx, View } from 'dripsy';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { DrawerView } from 'app/components/menu-list/drawerview';
import * as UserAction from 'app/redux/actions/user.action';
import * as NotificationAction from 'app/redux/actions/notification.action';
import * as globalAction from 'app/redux/actions/global.action';

import { Platform, SafeAreaView, ViewStyle } from 'react-native';
import { Footer } from 'app/components/footer';
// import { StaticFooter } from 'app/components/footer/static-footer';

import { useRouter } from 'solito/router';
import { useCurrentRoute } from 'app/redux/hooks/use-current-route';
import { useDim } from 'app/redux/hooks/useDim';
import { getClientConfig, getGlobalStore, getLoaded, getNotification, getPermission, getUser, getWallet } from '../../redux/memoizedSelectors';

const ComponentLayout = ({
  user,
  children,
  logout,
  leftMenu = true,
  isNavBar = true,
  permission,
  wallet,
  notification,
  getUserNotifications,
  updateUserNotifications,
  showSearchBox = false,
  isPrivatePage = false,
  leftMenuProps,
  loaded,
  showsHorizontalScrollIndicator = true,
  showsVerticalScrollIndicator = true,
  showFullFooter = false,
  // showSmallFooter = true,
  setViewHeight = false,
  scrollEnabled = true,
  searchDefaultValue = null,
  showNavbarBorder = true,
  updateGlobalStoreData,
  globalStore,
  loggedInNavbar,
  navBarSearchBoxPlaceholder,
  containerStyle,
  clientConfig,
  extraHeight=0,
  extraScrollHeight=0
}: {
  permission: any[];
  user: any;
  children?: any;
  logout: (navigate: any) => void;
  leftMenu?: boolean;
  isNavBar?: boolean;
  wallet: any;
  notification: any;
  getUserNotifications: (userId: any) => void;
  updateUserNotifications: (obj: any) => any;
  showSearchBox?: boolean;
  isPrivatePage?: boolean;
  leftMenuProps?: any;
  loaded?: boolean;
  showsHorizontalScrollIndicator?: boolean;
  showsVerticalScrollIndicator?: boolean;
  scrollEnabled?: boolean;
  showFullFooter?: boolean;
  showSmallFooter?: boolean;
  setViewHeight?: boolean;
  showNavbarBorder?: boolean;
  searchDefaultValue?: any;
  updateGlobalStoreData: (minimize: any) => void;
  globalStore: any;
  loggedInNavbar?: boolean;
  navBarSearchBoxPlaceholder?: string;
  containerStyle?: ViewStyle;
  clientConfig: any;
  extraHeight?:number;
  extraScrollHeight?:number;
}) => {
  const sx = useSx();
  const { height } = useDim();
  const { replace } = useRouter();
  const currentRoute = useCurrentRoute();

  useEffect(() => {
    if (!loaded) return; 
    
    if (isPrivatePage && !user?._id) {
      let _currentRoute = currentRoute || '/';
      if (_currentRoute.includes('/client-checkout')) {
        _currentRoute = '/client-subscription';
      }
      // remove all state

      if(Platform.OS === 'web')
        replace(`/account/signin?continue=${_currentRoute}`);
      else
        replace('/account/signin');
    }
  }, [user, currentRoute, isPrivatePage, loaded]);
  const layoutHeight = useMemo(() => {
    return (
      height -
      25 -
      (['android', 'ios'].includes(Platform.OS)
        ? 40
        : isNavBar
          ? setViewHeight
            ? 35
            : 60
          : 0)
    );
  }, [height, isNavBar, setViewHeight]);
  return (
    <SafeAreaView
      style={sx({
        flex: 1,
        // height: '100%',
      })}
    >
      <View
        style={sx({
          flex: 1,
          display: 'flex',
          overflow: 'hidden',
          ...(showFullFooter && !setViewHeight
            ? {}
            : {
              maxHeight: [height + 10, height],
            }),
        })}
      >
        {isNavBar ? (
          <NavBar
            showNavbarBorder={showNavbarBorder}
            wallet={wallet}
            notification={notification}
            getUserNotifications={getUserNotifications}
            updateUserNotifications={updateUserNotifications}
            permission={permission}
            user={user}
            logout={logout}
            showSearchBox={showSearchBox}
            searchDefaultValue={searchDefaultValue}
            navBarSearchBoxPlaceholder={navBarSearchBoxPlaceholder}
            loggedInNavbar={loggedInNavbar}
            clientConfig={clientConfig}
          />
        ) : (
          <></>
        )}
        <View
          style={sx({
            flex: 1,
            display: 'flex',
            flexDirection: 'row-reverse',
            marginTop: isNavBar ? 70 : 0,
            ...(showFullFooter && !setViewHeight
              ? {}
              : sx({
                height: [layoutHeight],
                maxHeight: [layoutHeight],
                minHeight: [layoutHeight],
              })),
          })}
        >
          <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
            nestedScrollEnabled={true}
            style={[sx({
              flex: 1,
              height: layoutHeight,
              width: '100%',
              backgroundColor: '$white',
              minHeight: layoutHeight,
            })]}
            showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
            showsVerticalScrollIndicator={showsVerticalScrollIndicator}
            scrollEnabled={scrollEnabled}
          >
            <View style={[{ minHeight: height - 100, flex: 1 }, containerStyle]}>{children}</View>
            {showFullFooter && <Footer clientConfig={clientConfig} />}
          </ScrollView>
          {leftMenu ? (
            <View
              style={sx({
                display: ['none', 'none', 'flex'],
                padding: 0,
              })}
            >
              <DrawerView
                leftMenuProps={leftMenuProps}
                user={user}
                permission={permission}
                globalStore={globalStore}
                updateGlobalStoreData={(value) =>
                  updateGlobalStoreData({
                    minimizeDashboardMenu: value,
                  })
                }
              />
            </View>
          ) : (
            <></>
          )}
        </View>
        {/* {!showFullFooter && showSmallFooter ? (
          <StaticFooter />
        ) : <></>} */}
      </View>
    </SafeAreaView>
  );
};

const mapStateToProps = (state: any) => ({
  user: getUser(state).user,
  permission: getPermission(state).permission,
  wallet: getWallet(state).wallet,
  notification: getNotification(state).notification,
  loaded: getLoaded(state).loaded,
  globalStore: getGlobalStore(state).globalStore,
  clientConfig: getClientConfig(state).clientConfig,
});

const Layout = connect(mapStateToProps, {
  ...UserAction,
  ...NotificationAction,
  ...globalAction,
})(ComponentLayout);

export default Layout;
